import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { sistema } from "../../model/Sistema";

export default ({usuario, ...props}) => {
  const history = useHistory();
  if (!sistema.actual || !sistema.actual.accion || (!sistema.actual.accion !== 'completarOnboarding'))
  {
    history.push('/home');
  }

  return (
    <div className="exito-page">
      <i className="exito__icon icon--check"></i>
      <h1 className="exito__titulo">¡Gracias por completar tu perfil!</h1>
    </div>
  );
}