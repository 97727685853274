import React from "react";

export default ({item, titulo, descripcion, checked, onClick, ...props}) => {

  return (
    <button className={"check-multitexto" + (checked ? ' is-checked' : '')} onClick={() => {onClick && onClick(item)}}>
      <div className="check-multitexto-textos">
        <h1 className="check-multitexto-textos__titulo">{titulo}</h1>
        <p className="check-multitexto-textos__descripcion">{descripcion}</p>
      </div>
      <div className="check-multitexto-icon"><i className="icon--check"></i></div>
    </button>
  );
}