import React from 'react';

export default ({usuario, titulo, onClick, imagen, disabled, ...props}) => {

  return (
    <button disabled={disabled} className="menu-list-item__button" onClick={onClick} >
      <div className="menu-list-item__icon"><img src={imagen ? ("/img/"+imagen+".svg") : ""} alt="" /></div>
      <span className="menu-list-item__title">{titulo}</span>
      {props.children}
    </button>
  );
}
