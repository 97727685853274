import React from 'react';

export default ({usuario, ...props}) => {

  return(
    <section className="status-bar">
      <ul className="status-bar__controls">
        <li>
          <button className="status-bar__control-button button--user-avatar" title="Chat" onClick={props.showMenu} >
            <div className="button--user-avatar__content-wrapper">
              <i className="button--user-avatar__notification-icon icon--circle"></i>
              {usuario.avatar
                ? <img className="button--user-avatar__image" src={usuario.avatar} alt="" />
                : <div className="button--user-avatar__image"><span className="button--user-avatar__text">{(usuario.nombres ? usuario.nombres[0] : '') + (usuario.apellidos ? usuario.apellidos[0] : '')}</span></div>
              }
            </div>
          </button>
        </li>
      </ul>
    </section>
  );
}