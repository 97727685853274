import React, { useEffect, useState } from 'react';
import TituloConBack from '../../components/TituloConBack';
import Transaccion from './components/Transaccion';
import { getUIData, sendRequest } from '../../lib/serverAPI';
import moment from 'moment';

const Transacciones = ({ usuario, ...props }) => {
  const [transacciones, setTransacciones] = useState([]);

  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [filtros, setFiltros] = useState(null);

  const [pageSize, setPageSize] = useState(200);
  const [page, setPage] = useState(1);

  const [consultarDatos, setConsultarDatos] = useState(false);

  const actualizarFiltros = () => {
    setFiltros({ fechaDesde: fechaDesde, fechaHasta: fechaHasta, aprobado: 1 });
  }

  const actualizarDatos = () => {
    setConsultarDatos(true);
  }

  const resetFechas = () => {
    let hoy = moment(new Date()).format('YYYY-MM-DD');
    let comienzoDeMes = moment(new Date()).startOf('month').format('YYYY-MM-DD');

    setFechaDesde(comienzoDeMes);
    setFechaHasta(hoy);

    setTimeout(actualizarDatos,1000);
  }

  useEffect(() => {
    if (!!consultarDatos && !!filtros) {
      setConsultarDatos(false);
      getUIData('getTransacciones', { ...filtros, limit: pageSize, offset: pageSize * (page - 1) }, data => {
        setTransacciones(data.map(item => {
          item.fecha = item.time_approved;
          item.creditos = item.amount;
          item.titulo = item.motive;
          item.espacio = { id: item.space_id, nombre: item.space_name };
          item.solicitante = { id: item.agent_id, nombre: item.agent_name };
          item.aprobador = { id: item.approver_id, nombre: item.approver_name };
          item.saldo = parseFloat(item.saldo_renovable) + parseFloat(item.saldo_comprado);

          return item;
        }
          ));
      });
    }
  }, [consultarDatos]);

  useEffect(actualizarFiltros, [fechaDesde, fechaHasta]);

  useEffect(() => {
    resetFechas();
  }, []);

  return (
    <div className="transacciones-page">
      <TituloConBack titulo="Transacciones" />
      <div className="filtros">
        <div className="input-label-combo">
          <input className={"inputFecha" + ' tiene-valor'} type="date" onInput={(event) => {setFechaDesde(event.target.value)}} value={fechaDesde} />
          <label className="is--placeholder" >Desde</label>
        </div>
        <div className="input-label-combo">
          <input className={"inputFecha" + ' tiene-valor'} type="date" onInput={(event) => {setFechaHasta(event.target.value)}} value={fechaHasta} />
          <label className="is--placeholder" >Hasta</label>
        </div>
        <button className="control--aplicar" onClick={actualizarDatos}>Aplicar filtros</button>
      </div>
      <section className="transacciones-list">
        <div className="transacciones-columns">
          <div className="transacciones-item__date">
            <span className="transacciones-item__fecha">Fecha</span>
          </div>
          <div className="transacciones-item__details">
            <div className="transacciones-item__type">
              <span>Motivo</span>
            </div>
            <div className="transacciones-item__solicitante">
              <span>Miembro</span>
            </div>
            <div className="transacciones-item__espacio">
              <span>Espacio</span>
            </div>
            <div className="transacciones-item__credits">
              <span>Monto</span>
            </div>
            <div className="transacciones-item__saldo">
              <span>Saldo</span>
            </div>
          </div>
        </div>
        <ol className="transacciones-items">
          {transacciones && (transacciones.length > 0) && transacciones.map((item, index) => (
            <li key={item.id} className="transacciones-item">
              <Transaccion {...item} />
            </li>
          ))}
          <li><span className="control--ver-mas">{'mostrando ' + transacciones.length + ' registros'}
            {/* <img className="control--ver-mas__icon" src="img/chevron--orange.svg"></img> */}
          </span></li>
        </ol>
      </section>
    </div>
  );
}

export default Transacciones;