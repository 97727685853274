import React, { useState, useEffect, useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { login, logout } from "./lib/auth";
import APIManager from "./managers/APIManager";
import SplashScreen from "./screens/Splash";
import Main from "./screens/Main";
import "./scss/styles.scss";
import Usuario from "./model/Usuario";
import { sistema } from "./model/Sistema";
import { useInterval } from './lib/useInterval';
import { getSistemaData } from "./lib/serverAPI";

function App({version}) {
  const [isLoading, setIsLoading] = useState(true);
  const [usuario, setUsuario] = useState(null);
  const [isPortrait, setIsPortrait] = useState(true);

  const verifyLoading = setInterval(() => {
    if (sistema && sistema.usuario && (sistema.espacios.length > 0)) {
      sistema.setEspacioID(sistema.space_id);
      delete(sistema.space_id);
      setIsLoading(false);
      clearInterval(verifyLoading);
    }
  }, 500);

  const setLoginData = (usuarioData) => {
    //sistema.usuario = new Usuario(usuarioData);
    sistema.usuario = new Usuario(usuarioData);
    sistema.space_id = usuarioData.espacio_id;
    sistema.oficinas = usuarioData.oficinas;
    let sistemaData = getSistemaData();
    sistema.noImageURL = sistemaData.noImageURL;
    sistema.verFotosDeSedes = sistemaData.verFotosDeSedes;
    setUsuario(sistema.usuario);

    window.sistema = sistema; /*** COMENTAR REFERENCIA PARA DEPLOY ***/
  };

  const doLogout = () => {
    setIsLoading(true);
    return logout(setUsuario);
  };

  useEffect(() => {
    if (usuario && isLoading) {
      APIManager.getEspacios((espacios) => {
        sistema.espacios = espacios;
      }, error => {
        if (!usuario.activo) {
          setIsLoading(false);
        }
      });
    }

    const doLogin = () => {
      return login(setLoginData);
    };

    if (!usuario) {
      doLogin();
    }

    if (usuario && sistema.espacio) {
      setIsLoading(false);
    }
  }, [usuario, isLoading]);

  useLayoutEffect(() => {
    function updateIsPortrait() {
      setIsPortrait(window.innerWidth < window.innerHeight);
    }
    window.addEventListener("resize", updateIsPortrait);
    updateIsPortrait();
    return () => window.removeEventListener("resize", updateIsPortrait);
  }, []);

  useInterval(()=>{
    sistema.refreshUser(setUsuario);
  }, 0.5 * 1000 * 60);

  return (
    <main className="App">
      {isLoading ? (
        <SplashScreen version={version} />
      ) : usuario ? (
        <Router>
          <Switch>
            <Route path="/login" render={() => { doLogout(); }}
            />
            <Route path="/" render={({ ...props }) => (
                <Main {...props} version={version} usuario={usuario} setUsuario={setUsuario} doLogout={doLogout} isPortrait={isPortrait} />
              )}
            />
          </Switch>
        </Router>
      ) : null}
    </main>
  );
}

export default App;
