import React from 'react';

export default ({onClick, disabled, ...props}) => {

  return (
    <div className="event-item__controls">
      <button className="event-item__more-information" disabled={disabled} onClick={onClick}>
        <i className="icon--chevron-right"></i>
      </button>
    </div>
)
}