import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import Draggable from "react-draggable";
import TituloConBack from "../../components/TituloConBack";
import APIManager from '../../managers/APIManager';

export default ({ usuario, ...props }) => {
  const [codigo, setCodigo] = useState(null);

  const obtenerCodigoQR = () => {
    APIManager.getCode('access', (data) => {
      if (!!data.codigo) {
        setCodigo(data.codigo);
      } else {
        setCodigo(null);
      }
    }
    );
  }

  useEffect(() => {
    obtenerCodigoQR();
  }, []);

  return (
    <div className="qr-page">
      <TituloConBack titulo={"QR de Acceso"} />
      {!!codigo
        ? <>
          <p className="instrucciones">Mostrá el QR para acceder a la sede.</p>
          <Draggable>
            <div className="wrapper--qr-code">
              <QRCode className="codigo-QR" value={codigo} renderAs="svg" />
            </div>
          </Draggable>
          <p className="instrucciones--extra">Podés arrastrar el QR dentro de la pantalla.</p>
        </>
        : <p className="instrucciones">Para obtener un QR de acceso a la sede, primero debes realizar alguna reserva para el día de hoy.</p>
      }
    </div>
  );
}