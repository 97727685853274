import React from 'react';
import { useHistory } from 'react-router-dom';

export default ({hideMenu, usuario, ...props}) => {
  const history = useHistory();

  const pathClassName = (seccion) => {
    return props.seccion === seccion ? ' is-active' : '';
  }

  const setPage = (page) => {
    props.setSeccion(page);
    history.push(page);
  }

  return(
    <nav className="primary-navigation">
      <ul className="navigation-list">
        <li className={"navigation-item" + pathClassName('/home')}>
          <button className="navigation-item-link" onClick={() => {hideMenu(); setPage("/home")}}>
            <i className="icon-- home"></i>
            <span>Home</span>
          </button>
        </li>
        <li className={"navigation-item" + pathClassName('/experiencias')}>
          <button className="navigation-item-link" onClick={() => {hideMenu(); setPage("/experiencias")}}>
            <i className="icon-- experiencias"></i>
            <span>Experiencias</span>
          </button>
        </li>
        <li className={"navigation-item" + pathClassName('/reservas')}>
          <button className="navigation-item-link" disabled={!(usuario.status === 'approved')} onClick={() => {(usuario.status === 'approved') && hideMenu() && setPage("/reservas")}}>
            <i className="icon-- reservas"></i>
            <span>Reservar</span>
          </button>
        </li>
        <li className={"navigation-item" + pathClassName('/comunidad')}>
          <button className="navigation-item-link" onClick={() => {hideMenu(); setPage("/comunidad")}}>
            <i className="icon-- comunidad"></i>
            <span>Comunidad</span>
          </button>
        </li>
      </ul>
    </nav>
  );
}