import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CheckMultitexto from "../../components/CheckMultitexto";
import FixedCTA from "../../components/FixedCTA";
import Selector from "../../components/Selector";
import { sistema } from "../../model/Sistema";
import APIManager from '../../managers/APIManager';
import { sendRequest } from '../../lib/serverAPI';
import Usuario from '../../model/Usuario';

export default ({usuario, ...props}) => {
  const history = useHistory();
  const [accion, setAccion] = useState(sistema.actual && sistema.actual.accion && sistema.actual.accion ? sistema.actual.accion : null);
  const [espacios, setEspacios] = useState(sistema.espacios);
  const [espacio, setEspacio] = useState(sistema.actual && sistema.actual.espacio ? sistema.espacios.find(item => item.id === sistema.actual.espacio.id) : sistema.espacio);
  const [membresia, setMembresia] = useState(sistema.actual && sistema.actual.membresia ? sistema.actual.modosDeContratacion.find(item => item.id === sistema.actual.membresia.id) : null);
  const [membresias, setMembresias] = useState(sistema.actual && sistema.actual.modosDeContratacion ? sistema.actual.modosDeContratacion : []);
  const [nombres, setNombres] = useState(usuario.nombres ? usuario.nombres : (sistema.actual.nombres ? sistema.actual.nombres : ''));
  const [apellidos, setApellidos] = useState(usuario.apellidos ? usuario.apellidos : (sistema.actual.apellidos ? sistema.actual.apellidos : ''));
  const [datosCompletos, setDatosCompletos] = useState(false);
  const relacionesLaborales = [{id: 'Freelancer', nombre: 'Individual / Freelancer'}, {id: 'Empleado', nombre: 'Miembro de una empresa'}];
  const [relacionLaboral, setRelacionLaboral] = useState(sistema.actual && sistema.actual.relacionLaboral ? relacionesLaborales.find(item => item.id === sistema.actual.relacionLaboral.id) : relacionesLaborales[0]);
  const [nombreDeEmpresa, setNombreDeEmpresa] = useState('');
  const [empresas, setEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState(null);
  const [empresaFolded, setEmpresaFolded] = useState(true);
  const [posicion, setPosicion] = useState('');

  useEffect(() => {
    if (!sistema.actual) {
      if ( localStorage.actual) {
        sistema.actual = localStorage.actual
        if (sistema.actual.accion) {
          setAccion(sistema.actual.accion);
        }
      } else {
        sistema.actual = {};
      }
    }
    // if (!sistema.actual.modosDeContratacion) {
      APIManager.getList('modosDeContratacion',{},(response) => {
        sistema.actual.modosDeContratacion = response.data.listado;
        localStorage.actual = JSON.stringify(sistema.actual);
        setMembresias(sistema.actual.modosDeContratacion);
      });
    // }
  }, []);

  const editarPerfil = () => {
    sistema.actual.accion = 'completarOnboarding';
    localStorage.actual = JSON.stringify(sistema.actual);
    setAccion(sistema.actual.accion);
  }

  const changeNombres = (event) => {
    setNombres(event.target.value);
  }

  const changeApellidos = (event) => {
    setApellidos(event.target.value);
  }

  const changeRelacionLaboral = (item) => {
    setMembresia(null);
    setNombreDeEmpresa('');
    setEmpresa(null);
    setEmpresas([]);
    setRelacionLaboral(item);
    setEmpresaFolded(true);
    setPosicion('');
  }

  const changeNombreDeEmpresa = (event) => {
    setNombreDeEmpresa(event.target.value);
  }

  const changeEmpresa = (item) => {
    setEmpresa(item);
  }

  const changePosicion = (event) => {
    setPosicion(event.target.value);
  }

  useEffect(() => {
    APIManager.getList('empresas', {nombre: nombreDeEmpresa}, (response) => {
      const empresasMatch = [{id: null, nombre: '+ Crear empresa', descripcion: 'Crear mi empresa'}].concat(response.data.listado);
      setEmpresas(empresasMatch);
      setEmpresa(null);
      setEmpresaFolded(false);
    })
  }, [nombreDeEmpresa])

  useEffect(() => {
    setDatosCompletos(nombres && apellidos
      && espacio
      && relacionLaboral
      && (!empresa || posicion)
      && ((((relacionLaboral.id === 'Freelancer') || (empresa && !empresa.id)) && membresia) || (empresa && empresa.id)));
  }, [nombres, apellidos, espacio, membresia, relacionLaboral, empresa, posicion]);

  const finarlizarOnboarding = () => {
    sistema.actual.nombres = nombres;
    sistema.actual.apellidos = apellidos;
    sistema.actual.espacio = espacio;
    sistema.actual.membresia = membresia;
    sistema.actual.empresa = empresa;
    sistema.actual.posicion = posicion;
    sistema.actual.relacionLaboral = relacionLaboral;
    if (empresa && !empresa.id)
    {
      sistema.actual.empresa.nombre = nombreDeEmpresa;
    }

    localStorage.actual = JSON.stringify(sistema.actual);
    sendRequest('post','usuario',sistema.actual.accion, sistema.actual, (response) => {
      if (response.success) {
        // TODO: Actualizar los datos del usuario
        sistema.usuario = new Usuario(response.data);
        sistema.usuario.membresia = null; // Aseguramos no tener membresia (porque aun no fui aceptado)
        sistema.espacio = sistema.actual.espacio;
        props.setUsuario(sistema.usuario);
        history.push('/exitoonboarding');
      }
    })
  }

  return (
    (accion === 'completarOnboarding')
    ?
      <div className="onboarding-page">
        <h1 className="titulo nombre__title">Completá tu nombre y apellido</h1>
        <div className="input-label-combo">
          <input className={"inputNombres" + (nombres ? ' tiene-valor' : '')} id="inputNombres" type="text"  onInput={changeNombres} defaultValue={nombres} />
          <label className="is--placeholder" htmlFor="inputNombres">Nombres</label>
        </div>
        <div className="input-label-combo">
          <input className={"inputApellidos" + (apellidos ? ' tiene-valor' : '')} id="inputApellidos" type="text" onInput={changeApellidos} defaultValue={apellidos} />
          <label className="is--placeholder" htmlFor="inputApellidos">Apellidos</label>
        </div>
        <h1 className="titulo sedes__title">Seleccioná la sede que te quede mas cómoda</h1>
        <Selector titulo="Sede" selectedItem={espacio} items={espacios} icon="" onChange={setEspacio} folded={true}/>
        <h1 className="titulo relacionLaboral__title">Indicanos si tu membresia es individual o corporativa</h1>
        <Selector titulo="Relacion Laboral" selectedItem={relacionLaboral} items={relacionesLaborales} onChange={changeRelacionLaboral} folded={true} />
        {relacionLaboral && (relacionLaboral.id === 'Empleado') && <>
          <div className="input-label-combo">
            <input className={"inputEmpresa" + (nombreDeEmpresa ? ' tiene-valor' : '')} id="inputEmpresa" type="text" onInput={changeNombreDeEmpresa} defaultValue={empresa} />
            <label className="is--placeholder" htmlFor="inputEmpresa">Empresa</label>
          </div>
          <Selector titulo="Empresa seleccionada" selectedItem={empresa} items={empresas} onChange={changeEmpresa} folded={empresaFolded} />
          <div className="input-label-combo">
            <input className={"inputPosicion" + (posicion ? ' tiene-valor' : '')} id="inputPosicion" type="text" onInput={changePosicion} defaultValue={posicion} />
            <label className="is--placeholder" htmlFor="inputPosicion">Cargo</label>
          </div>
        </>}
        {relacionLaboral && ((relacionLaboral.id === 'Freelancer') || (empresa && !empresa.id)) && <>
          <h1 className="titulo membresias__title">Seleccioná la membresía que te interese</h1>
          <ul className="membresias-list">
            {membresias && (membresias.length > 0) && membresias.map((item, index) =>
              <li className="membresias-list-item" key={index} >
                <CheckMultitexto item={item} titulo={item.nombre} descripcion={item.descripcion} checked={membresia === item} onClick={setMembresia} />
              </li>
            )}
          </ul>
        </>}

        <FixedCTA label="Confirmar" disabled={!datosCompletos} onClick={finarlizarOnboarding} />
      </div>
    :
      <div className="exito-page">
        <i className="exito__icon icon--check"></i>
        <h1 className="exito__titulo">¡Tu cuenta fue creada con éxito!</h1>
        <span className="exito__leyenda">Para poder utilizar los servicios de La Maquinita, te pedimos que completes la información básica de tu perfil</span>
        <button onClick={editarPerfil} className="exito__control">Completar mi perfil</button>
        {/*
          // Ya no se puede continuar sin completar perfil porque genera problemas con los miembros que quieren reservar
          // La idea era permitir a miembros que miren la plataforma antes de contratar
          // La realidad es que contratan, se meten y no completan el perfil
        <button onClick={() => {history.push('/home')}} className="exito__control-secundario">Continuar sin completar perfil</button>
        */}
      </div>
  );
}