import { sendRequest, getUIData } from '../lib/serverAPI';

export default class APIManager {
  static getList(lista, data = {}, successCallback, errorCallback) {
    data.lista = lista;
    sendRequest('post','query','getList', data, (response) => {
      if (response.success && successCallback) {
        return successCallback(response);
      }
      if (!response.success && errorCallback) {
        return errorCallback(response);
      }
    })
  }

  static getEspacios(successCallback, errorCallback) {
    this.getList('espacios', {activos: true}, (response) => successCallback(response.data.listado), errorCallback);
  }

  static getSectores(espacio, fecha, turno, successCallback, errorCallback) {
    this.getList('sectores', {espacioID: espacio.id, date: fecha, turno: turno, conCupoEscritorio: true, conDisponibleEscritorio: true}, (response) => successCallback(response.data.listado), errorCallback);
  }

  static getSalasConReservas(espacio, fecha, successCallback, errorCallback, tipo = 'SALA') {
    getUIData('getSalasConReservas',{espacioID: espacio.id, fecha: fecha, tipo: tipo}, successCallback, errorCallback);
  }

  static getCode(tipo, callback, errorCallback = null) {
    getUIData('getCodigo', {tipo: 'miembro'}, callback, errorCallback);
  }
}
