import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MenuItem from "./components/MenuItem";
import PerfilCTA from "./components/PerfilCTA";
import Cover from "./components/Cover";

export default function Menu({toggleMenu, visible, ...props }) {
  const history = useHistory();

  const setPage = (page) => {
    history.push(page);
    toggleMenu();
  }

  return (
    <div className={"menu" + (visible ? "" : " hidden")}>
      <Cover toggleMenu={toggleMenu} {...props} />
      <div className="menu-scrollable-content">
        <PerfilCTA {...props} />
        <ul className="menu-list">
          <li className="menu-list-item"><MenuItem titulo="Mostrar QR" onClick={() => {setPage("/qr")}}><img src="img/icon--qrcode.png" alt="Icono de QR" className="icon--QR menu-list-item__creditos" /></MenuItem></li>
          <li className="menu-list-item"><MenuItem titulo="Créditos" onClick={() => { setPage("/creditos") }}><span className="menu-list-item__creditos">{props.usuario.creditos ? props.usuario.creditos.balance : ''}</span></MenuItem></li>
          {/* <li className="menu-list-item"><MenuItem titulo="Notificaciones"/></li> */}
          <li className="menu-list-item"><MenuItem titulo="Perfil" onClick={() => {setPage("/perfil")}} /></li>
          {/* <li className="menu-list-item"><MenuItem titulo="Favoritos"/></li> */}
          <li className="menu-list-item"><MenuItem titulo="Historial" onClick={() => { setPage("/historial") }} /></li>
          <li className="menu-list-item"><MenuItem titulo="Transacciones" onClick={() => {setPage("/transacciones")}} /></li>
          {/* <li className="menu-list-item"><MenuItem titulo="Métodos de Pago"/></li> */}
          {/* <li className="menu-list-item"><MenuItem titulo="Ayuda"/></li> */}
          <li className="menu-list-item">
            <MenuItem titulo="Cerrar sesión" onClick={() => {
                setPage("/login");
              }}
            />
          </li>
        </ul>
        <span className="menu-list__version">{ "v" + props.version }</span>
      </div>
    </div>
  );
}
