import React, { useEffect, useState } from "react";

export default ({ sector, onClick }) => {

  const [isUnfurled, setIsUnfurled] = useState(false);
  /*
  const nombreFromUtilidad = (utilidad) => {
    switch (utilidad.nombre)
    return ''
  }
  */
  return (
    <div className="card--info-de-sala">
      <div className="layout--image-wrapper">
        <img className="info-de-sala__image" src={sector.imagen} alt="Imagen Del Sector" />
      </div>
      <div className="info-de-sala__detalle">
        <div className="layout--detalles">
          <div className="layout--column">
            <h1 className="info-de-sala__nombre">{sector.nombre }</h1>
            {/* <div className="wrapper--info-de-sala">
              <div className="info-de-sala__costo">{ sector.costoPorHora } créditos</div>
              <div className="info-de-sala__capacidad">{ sector.capacidad } personas</div>
            </div> */}
          </div>
          <div className="layout--column">
            <button className="control--elegir-sala button--primary" onClick={onClick}>Elegir</button>
          </div>
        </div>
      </div>
{/*       { (Object.values(sector.utilidades).length > 0) &&
        <div className="info-de-sector__features">
          <div className={"layout--row wrapper--features-de-la-sector" + (isUnfurled ? ' is--unfurled' : '')}>
            <ul className="features-de-la-sector">
              {Object.values(sector.utilidades).map((utilidad) =>
                <li key={utilidad.id} className="feature-de-la-sector">{ utilidad.descripcion }</li>
              )}
            </ul>
          </div>
          <div className="layout--row wrapper--mostrar-features">
            <button className="control--mostrar-features" onClick={() => setIsUnfurled(!isUnfurled)}>
              <span>Ver Detalle</span>
              <img className={"selector-chevron" + (isUnfurled ? ' is--reversed' : '')} src="/img/chevron--orange.svg" alt="chevron" />
            </button>
          </div>
        </div>
      } */}
    </div>
  );
}