import React, { useEffect, useState } from 'react';
import Experiencia from '../Experiencia';

import { getUIData } from '../../lib/serverAPI';

export default ({eventos, ...props}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    getUIData('getExperienciasDestacadas', {}, setItems);
  }, [])

  return (
    items.length === 0 ? null :
    <section className="experiences-spotlight">
      <h1 className="experiences-spotlight__title">No te lo pod&eacute;s perder</h1>
      <ul className="experiences-list">
        {items.map((item) => (
          <li key={item.id} className={"experience" + (items[0] === item ? ' is-highlighted' : '')}>
            <Experiencia {...item} />
          </li>
        ))}
      </ul>
      {/* Comentado porque no tenemos como mostrar mas experiencias como las destacadas (no sabemos que es destacado)
      <div className="experiences-spotlight__controls">
        <a href="#mas" className="experiences-spotlight__more-experiences">
          M&aacute;s eventos como estos
          <i className="icon--chevron-right"></i>
        </a>
      </div>
        */}
    </section>
  );
}