import React from "react";
import { useHistory } from "react-router-dom";

export default ({titulo, onClick, ...props}) => {
  const history = useHistory();

  return (
    <div className={"titulo-back "} >
      <button className={"titulo-back__control"} onClick={() => {onClick ? onClick() : history.goBack()}}><span className="titulo-back__control-text">Volver</span><img className={"titulo-back__icon"} src="/img/icon--arrow.svg" alt="" /></button>
      <span className={"titulo-back__titulo"}>{titulo}</span>
      {props.children}
    </div>
  );
}
