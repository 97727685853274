import moment from 'moment';
import 'moment/locale/es';
import React from 'react';

export default ({ fecha }) => {

  moment.updateLocale('es', {
    weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  });
  moment.locale('es');

  const diaDeLaSemana = new Date(fecha).getUTCDay();
  const diaDelMes = new Date(fecha).getUTCDate();
  const fechaString = moment(fecha).format('DD MMM YYYY').toLocaleUpperCase();

  const diasDeLaSemana = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

  return (
    <div className="event-item__date">
      <span className="event-item__fullday">{diasDeLaSemana[diaDeLaSemana]}</span>
      <span className="event-item__day">{diasDeLaSemana[diaDeLaSemana].substr(0,3)}</span>
      <span className="event-item__fecha">{ fechaString }</span>
      {/* <span className="event-item__date-number">{diaDelMes}</span> */}
    </div>
  )
}