import React from 'react';

export default ({tipo, descripcion, importe, saldo, ...transaccion}) => (
  <div className="transacciones-item__details">
    <div className="transacciones-item__solicitante">
      <span><span className="item--nombre">Tipo: </span>{ tipo }</span>
    </div>
    <div className="transacciones-item__type">
      <span><span className="item--nombre">Descripcion: </span>{ descripcion }</span>
    </div>
    <div className="transacciones-item__credits">
      <span><span className="item--nombre">Importe: </span>{ (importe > 0 ? '+' : '') + parseFloat(importe).toLocaleString('es-AR',{style: 'currency',currency: 'ARS'})}</span>
    </div>
    <div className="transacciones-item__saldo">
      {/* <span className={(saldo > 0 ? 'balance__deudor' : 'balance__acreedor')} > */}
      <span>
        <span className={"item--nombre"}>Saldo: </span>
        {parseFloat(saldo).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}
      </span>
    </div>
  </div>
)