import moment from 'moment';
import 'moment/locale/es';
import React from 'react';

export default ({ fecha }) => {

  moment.updateLocale('es', {
    weekdays: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  });
  moment.locale('es');

  const diaDeLaSemana = new Date(fecha).getUTCDay();
  const diaDelMes = new Date(fecha).getUTCDate();
  const fechaString = moment(fecha).format('DD-MM-YYYY').toLocaleUpperCase();
  const timeString = moment(fecha).format('h:m:s');

  const diasDeLaSemana = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

  return (
    <div>
      <div className="transacciones-item__date">
        <span className="transacciones-item__fecha"><span className="item--nombre">Fecha: </span>{fechaString}</span>
        {/* <span className="event-item__date-number">{diaDelMes}</span> */}
      </div>
      <div>
        <span>{timeString}</span>
      </div>
    </div>
  )
}