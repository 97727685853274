import React from "react";

export default ({turno, ...props}) => {

  return (
    <button className={"reservaDePosicion-turno button-turno" + (props.checked ? ' is-checked' : '')} onClick={() => {props.clicked(turno)}}>
      <div className="reservaDePosicion-turno-textos">
        <h1 className="reservaDePosicion-turno-textos__titulo">{turno.titulo}</h1>
        <p className="reservaDePosicion-turno-textos__descripcion">{turno.descripcion}</p>
      </div>
      <div className="reservaDePosicion-turno-icon"><i className="icon--check"></i></div>
    </button>
  );
}