import React from 'react';
import MaquineroCard from '../MaquineroCard';

export default ({items, ...props}) => {

  return (
    !items || (items.length === 0) ? null :
      <ul className="comunidad-list">
        {items.map((item, index) => (
          <li key={item.id} className={"maquinero"}>
            <MaquineroCard {...item} />
          </li>
        ))}
      </ul>
  );
}