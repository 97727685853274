import React from 'react';
import Fecha from './components/Fecha';
import Info from './components/Info';
// import Controles from './components/Controles';
import { useHistory } from 'react-router-dom';

const Transaccion = ({...transaccion}) => {

  return (
  <>
    <Fecha {...transaccion} />
    <Info {...transaccion} />
    {/* <Controles {...evento}  onClick={cancelarEvento}/> */}
  </>
  )
}

export default Transaccion;