import React from "react";
import { AppBar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Stack, TextField, Toolbar, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import SignaturePad from 'react-signature-canvas';
import { variablesAplicadas } from "../../lib/templateFunctions";
import { sendRequest } from "../../lib/serverAPI";
import APIManager from "../../managers/APIManager";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TituloConBack from "../../components/TituloConBack";
import FixedCTA from "../../components/FixedCTA";

const FirmarContrato = ({usuario, ...props}) => {

  const sigRef = useRef();
  const [signature, setSignature] = useState(null);

  const [contrato, setContrato] = useState(null);
  const [template, setTemplate] = useState(null);
  const [nombreFirmante, setNombreFirmante] = useState('');
  const [cuitFirmante, setCuitFirmante] = useState('');
  const [razonSocialFirmante, setRazonSocialFirmante] = useState('');
  const [caracteres, setCaracteres] = useState([]);
  const [caracterFirmante, setCaracterFirmante] = useState('');
  const [firmaURL, setFirmaURL] = useState('');

  const [firmante, setFirmante] = useState(null);

  const [documentoContent, setDocumentoContent] = useState('');
  const [viendoContrato, setViendoContrato] = useState(false);

  const [puedeGuardar, setPuedeGuardar] = useState('false');
  const [procesando, setProcesando] = useState(null);

  const history = useHistory();

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  }

  const trimSignature = () => {
    setFirmaURL(signature);
    sigRef.current.clear();
  }

  const clearSignature = () => {
    setFirmaURL('');
    if (!!sigRef.current) {
      sigRef.current.clear();
    }
    setSignature(null);
  }

    const handleCloseContrato = () => {
    setViendoContrato(false);
  }

  const handleVerContrato = () => {
    if (!!contrato && !!template) {
      variablesAplicadas(template, [], contrato, htmlBody => {
        setDocumentoContent(htmlBody);
        setViendoContrato(true);
      });
    }
  }

  const handleConfirmar = () => {
    setProcesando(true);

    sendRequest('post', 'contratos', 'firmarContrato', { contrato, firmante }, response => {
      if (response.success) {
        firmante.firmaURL = response.data.firmaURL;
        const contratoParaDocumento = { ...contrato, firmante: firmante };
        variablesAplicadas(template, [], contratoParaDocumento, htmlBody => {
          sendRequest('post', 'contratos', 'generarPDF', { documento: htmlBody, contrato: contratoParaDocumento }, response => {
            if (response.success) {
              // Se grabó el PDF y tenemos el filename para poner en el contrato
              window.open(response.data.documentoURL);
            }
            setProcesando(false);
            history.push('/');
          });
        });
      }
    });
  }

  const cargarTemplate = (templateID, callback) => {
    sendRequest('post', 'contratos', 'getTemplate', { id: templateID }, (templateResponse) => {
      if (templateResponse.success) {
        if (!!callback) {
          callback(templateResponse);
        }
      }
    });
  }

  useEffect(() => {
    if (!!contrato) {
      if (!usuario.contratoAdminAccess || (!!contrato.firmante && !!contrato.firmante.firma)) {
        // El contrato ya fue firmado!
        // O el usuario no puede firmar
        return history.push('/');
      }
      if (!!contrato.firmante && !!contrato.firmante.firma) {
        setNombreFirmante(contrato.firmante.nombre);
        setCuitFirmante(contrato.firmante.cuit);
        setRazonSocialFirmante(contrato.firmante.razonSocial);
        setFirmaURL(contrato.firmante.firmaURL);
        setCaracterFirmante(contrato.firmante.caracter);
      }
      if (!!contrato.template_id) {
        cargarTemplate(contrato.template_id, (response) => {
          if (response.success) {
            setTemplate(response.data.template);
          }
        });
      }
    }
  }, [contrato]);

  useEffect(() => {
    setPuedeGuardar(!!usuario.contratoAdminAccess && !!nombreFirmante && !!cuitFirmante && !!razonSocialFirmante && !!caracterFirmante && !!firmaURL);
    setFirmante({
      nombre: nombreFirmante,
      razonSocial: razonSocialFirmante,
      cuit: cuitFirmante,
      caracter: caracterFirmante,
      firmaFile: firmaURL,
    });
  }, [nombreFirmante, cuitFirmante, razonSocialFirmante, caracterFirmante, firmaURL]);

  useEffect(() => {
    APIManager.getList('caracteres', {}, response => {
      if (response.success) {
        setCaracteres(response.data.listado);
        setContrato(usuario.contrato);
      }
    })
  }, []);

  return (
    <div className="firmar-page">
      <TituloConBack titulo="Firma de contrato" />
      {!!viendoContrato && !!documentoContent &&
        <Dialog
          fullScreen
          open={viendoContrato} onClose={handleCloseContrato}>
          <AppBar sx={{ position: 'relative' }} >
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseContrato}
                aria-label="close"
              >
                <i className="icon--times"></i>
                {/* <CloseIcon /> */}
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Paper elevation={3} sx={{ padding: '24px' }} dangerouslySetInnerHTML={{ __html: documentoContent }} />
          </DialogContent>
          <DialogActions><Button onClick={handleCloseContrato}>Cerrar</Button></DialogActions>
        </Dialog>
      }
      {!!procesando && <Dialog open={true}><DialogTitle>{procesando?.title ?? ''}</DialogTitle><DialogContent><CircularProgress /></DialogContent></Dialog>}
      <div className="wrapper--scrollable-content__layout" >
        <Grid container marginTop="14px">
          <Grid item xs={12} justifyContent="center" display="flex" marginLeft={!!props.isPortrait ? '' : '200px'}>
            <button className={"contrato__button button--icon-only inline"} onClick={handleVerContrato}>
              <i className="icon--file-pdf"></i>
              <span>{'Ver Contrato Preliminar'}</span>
            </button>
          </Grid>
          {!props.isPortrait && <Grid item xs={12} sm={6}>
            <Stack justifyContent="flex-start" alignItems="center">
            {!firmaURL
              ? <SignaturePad canvasProps={{ className: 'signature' }} ref={sigRef} onEnd={handleSignatureEnd} />
              : <img src={firmaURL} width="80%" height="auto"></img>}
              <Stack direction="row" justifyContent="space-around" width="100%">
                <IconButton onClick={clearSignature}><i className="icon--trash attention"></i></IconButton>
                {!firmaURL && <IconButton onClick={trimSignature}><i className="icon--check attention"></i></IconButton>}
              </Stack>
            </Stack>
          </Grid>
          }
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <TextField
                required
                fullWidth
                id="nombre"
                label="Nombre"
                name="nombre"
                value={nombreFirmante}
                onChange={e=>setNombreFirmante(e.target.value)}
              />
              <TextField
                required
                fullWidth
                id="razonSocial"
                label="Razon Social"
                name="razonSocial"
                value={razonSocialFirmante}
                onChange={e=>setRazonSocialFirmante(e.target.value)}
              />
              <TextField
                required
                fullWidth
                id="cuit"
                label="CUIT"
                name="cuit"
                value={cuitFirmante}
                onChange={e=>setCuitFirmante(e.target.value)}
              />
              <FormControl variant="standard">
                <InputLabel id="caracter-label" shrink={true} >Caracter</InputLabel>
                <Select
                  id="caracter-select"
                  value={caracterFirmante}
                  onChange={e=>setCaracterFirmante(e.target.value)}
                  label="Caracter"
                >
                  {caracteres.map(
                    ((item,index) => <MenuItem key={index} value={item.nombre}>{item.nombre}</MenuItem>)
                  )}
                </Select>
              </FormControl>
              {!!props.isPortrait &&
                <Box>
                  <Typography>Firma registrada</Typography>
                    <Stack justifyContent="flex-start">
                      {!firmaURL
                        ? <SignaturePad canvasProps={{ className: 'signature' }} ref={sigRef} onEnd={handleSignatureEnd} />
                        : <img src={firmaURL} width="100%" height="auto"></img>}
                      <Stack direction="row" justifyContent="space-around" width="100%">
                        <IconButton onClick={clearSignature}><i className="icon--trash attention"></i></IconButton>
                        {!firmaURL && <IconButton onClick={trimSignature}><i className="icon--check attention"></i></IconButton>}
                      </Stack>
                    </Stack>
                </Box>
              }
            </Stack>
          </Grid>
        </Grid>
      </div>
      <FixedCTA label="Aplicar firma" disabled={!puedeGuardar} onClick={handleConfirmar} />
    </div>
  );
}


export default FirmarContrato;