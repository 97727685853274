import React from "react";

const TarjetaDeEmpleado = ({item, ...props}) => {
  return (
    <>
    <div className="layout--column">
      <div className="combo--nombre-apellido">
          <span className="nombre">{item.nombres + (!!item.nombres ? ' ' : '')}</span>
          <span className="apellido">{item.apellidos}</span>
      </div>
      {!!item.puesto && <span className="puesto">{item.puesto}</span>}
      <span className="email">{item.contact_email}</span>
    </div>
    {!!props.children && <div className="layout--column layout--controles">
      {props.children}
    </div>}
    </>
  );
}

export default TarjetaDeEmpleado;