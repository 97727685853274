import moment from "moment";
import { sistema } from "../model/Sistema";
import APIManager from '../managers/APIManager';

export const getVariables = (callback) => {
  APIManager.getList('variablesDeContratos', {}, (response) => {
    if (response.success) {
      const variables = response.data.listado;
      if (!!callback) {
        callback(response);
      }
    }
  });
}

export const variablesAplicadas = (template, variablesConInput, contrato, callback) => {
  getVariables(response => {
    const variables = response.data.listado;
    let html = template.body;
    if (variables.length > 0) {
      // Tenemos variables y contrato! Hay que parsear el documento
      // Para generar el PDF
      variables.forEach(variable => {
        const variableConInput = variablesConInput.find(item => item.nombre === variable.nombre);
        if (!!variableConInput && !!variableConInput.value) {
          variable.value = variableConInput.value.replace(new RegExp('\n', 'g'), '<br/>');
        }
        html = variableAplicada(html, variable, contrato);
      });
    }
    if (!!callback) {
      callback(html);
    }
  }
    );
};

/*
export const variablesAplicadas = (template, contrato, callback) => {
  APIManager.getList('variablesDeContratos', {}, (response) => {
    if (response.success) {
      const variables = response.data.listado;
      let html = template.body;
      if (variables.length > 0) {
        // Tenemos variables y contrato! Hay que parsear el documento
        variables.forEach(variable => {
          html = variableAplicada(html, variable, contrato);
        });
      }
      if (!!callback) {
        callback(html);
      }
    }
  });
};
 */
export const variableAplicada = (html, variable, contrato) => {
  // console.log('Reemplazando variable:', variable);
  const replaceText = '%' + variable.variable + '%';
  let valor = replaceText;
  switch (variable.nombre) {
    case 'fechaDeHoy':
      valor = moment(new Date);
      valor.locale('es');
      valor = valor.format('LL');
      break;
    case 'añoActual':
      valor = moment(new Date);
      valor.locale('es');
      valor = valor.format('YYYY');
      break;
    case 'puestosTotales':
      valor = Object.values(contrato.puestos).reduce((anterior, item) => anterior + parseInt(!!item.puestos ? item.puestos : 0), 0);
      break;
    case 'pageBreak':
      // console.log('pageBreak',variable);
      valor = "<h1 style='page-break-before: always'></h1>";
      break;
    case 'espacio.firma':
      if (!!contrato.espacio) {
        valor = "<img width='300px' src='"+contrato.espacio.firmaURLFull+"' />";
      }
      break;
    case 'firmante.firma':
      if (!!contrato.firmante) {
        valor = "<img width='300px' src='"+contrato.firmante.firmaURL+"' />";
      }
      break;
    case 'puestosDetalle':
      const puestosTotales = Object.values(contrato.puestos).reduce((anterior, item) => anterior + parseInt(!!item.puestos ? item.puestos : 0), 0);
      if (puestosTotales > 0) {
        valor = '<p>';
        Object.values(contrato.puestos).forEach(item => {
          if (parseInt(!!item.puestos ? item.puestos : 0) > 0) {
          valor += item.puestos + ' - ' + item.descripcion + ' - ' + item.creditos + ' créditos<br/>';
        }});
        valor += '</p>';
      }
      break;
    default:
      const partes = variable.nombre.split('.');
      // console.log('Variable:', variable, 'partes:', partes);
      if (partes.length === 1) {
        if (typeof(contrato[variable.nombre]) !== 'undefined') {
          valor = contrato[variable.nombre];
        } else if (typeof(sistema[variable.nombre]) !== 'undefined') {
          valor = sistema[variable.nombre];
        }
      } else {
        if (!!contrato[partes[0]]) {
          try {
            valor = contrato[partes[0]];
            valor = valor[partes[1]];
          } catch (e) {
            console.log('Error con variable en contrato ',variable,e);
          }
        } else if (!!sistema[partes[0]]) {
          try {
            valor = sistema[partes[0]];
            valor = valor[partes[1]];
          } catch (e) {
            console.log('Error con variable en sistema ',variable,e);
          }
        }
      }
      break;
  }
  if (!!variable.value) {
    valor = variable.value;
  }
  if ((valor === replaceText) || (valor === null) || typeof(valor) === 'undefined') {
    // console.log('La variable no pudo ser reemplazada', variable);
    valor = '';
  } else if (!!valor && !!valor.date) {
    // Es una fecha, tomamos date
    valor = moment(valor.date).locale('es').format('LL');
  } else if (!!valor && !!(valor instanceof Date)) {
    valor = moment(valor).locale('es').format('LL');
  }
  return html.replace(new RegExp(`${replaceText}`, 'g'), valor);
  // return html.replace(replaceText, valor);
}