import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';

export default function Cover({slider = true, titulo, images=[], ...props}) {

  return (
      !slider ?
      <div className="image-slider no-slider image-slider--placeholder" style={{backgroundImage: 'url('+images[0]+')'}}>
        <h1 className="image-slider__slide-text cover-title">{titulo}</h1>
      </div>
      :
      <Carousel controls={false} touch={true} >
        <Carousel.Item >
          <div className="image-slider image-slider--placeholder">
              <p className="image-slider__slide-text">Este puede ser un lugar para destacar</p>
          </div>
        </Carousel.Item>
        <Carousel.Item >
          <div className="image-slider image-slider--placeholder">
              <p className="image-slider__slide-text">Este puede ser otro lugar para destacar</p>
          </div>
        </Carousel.Item>
      </Carousel>

  );
}
