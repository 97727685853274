import React from 'react';
import Fecha from './components/Fecha';
import Evento from './components/Evento';
import Controles from './components/Controles';
import { sistema } from '../../model/Sistema';
import { useHistory } from 'react-router-dom';

export default ({evento, empresa}) => {
  const history = useHistory();

  const cancelarEvento = () => {
    switch (evento.tipo) {
      case 'Evento':
        history.push('/experiencia/'+evento.id);
        break;
      default:
        if (!sistema.actual) {
          sistema.actual = {};
        }
        sistema.actual.evento = evento;
        sistema.saveActual();
        history.push('/cancelar');
    }
  }

  return (
  <>
    <Fecha {...evento} />
    <Evento {...evento} />
    {/*(new Date(evento.fecha) >= new Date()) &&*/
      /*<Controles {...evento} disabled={new Date(evento.fecha) < new Date()} onClick={cancelarEvento} /> */
      <Controles {...evento}  onClick={cancelarEvento}/>
    }
  </>
  )
}