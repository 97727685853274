import React, { useEffect, useState } from "react";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from '../../model/Sistema';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import APIManager from '../../managers/APIManager';
import SectorCard from "../../components/SectorCard";

export default function InfoDeSectores({usuario, ...props }) {
  const history = useHistory();

  const [espacio, setEspacio] = useState(sistema.actual && sistema.actual.espacio ? sistema.espacios.find(item => item.id === sistema.actual.espacio.id) : sistema.espacio);
  const [sectores, setSectores] = useState(sistema.actual.sectores ? sistema.actual.sectores : []);

  useEffect(() => {
    // Hay que cargar las sectores
    if (espacio) {
      if (!sistema.actual) {
        if (!localStorage.actual) {
          sistema.actual = {};
        } else {
          sistema.actual = JSON.parse(localStorage.actual);
        }
      }
      sistema.actual.espacio = espacio;

      APIManager.getSectores(espacio, null, null, (lista) => {
        lista.forEach(item => {
          console.log(item, sistema.noImageURL,);
          if (!item.imagen) {item.imagen = sistema.noImageURL}});
        setSectores(lista);
      });
    }
  },[espacio]);


  const seleccionarSector = (sectorSeleccionado) => {
    if (!sistema.actual) {
      if (!localStorage.actual) {
        sistema.actual = {};
      } else {
        sistema.actual = JSON.parse(localStorage.actual);
      }
    }
    sistema.actual.sector = sectorSeleccionado;

    history.push('/posicion');

  }

  const infoDeSector = (item) => {
    if (!item) {
      return '';
    }

    let $infoExtra = '';
    $infoExtra += item.costoPorHora ? item.costoPorHora + ' cr/h' : '';
    $infoExtra += item.capacidad ? ' ' + item.capacidad + ' per' : '';
    return $infoExtra;
  }

  return (
    <div className={"info-de-salas-page"}>
      <TituloConBack titulo="Información de Sectores" />
      <div className="wrapper--scrollable-content__layout">
        <div className="wrapper--scrollable-content__column">
          <ol className="info-de-salas">
            {sectores.map((sector) =>
              <li key={sector.id} className="info-de-sala">
                <SectorCard sector={sector} onClick={() => seleccionarSector(sector)} />
              </li>
            )}
          </ol>
        </div>
      </div>

    </div>
  );
}
