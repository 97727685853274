import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DetalleConIconoYTexto from "../../components/DetalleConIconoYTexto";
import FixedCTA from '../../components/FixedCTA';
import { sistema } from "../../model/Sistema";

export default ({usuario, ...props}) => {
  const history = useHistory();
  if (!sistema.actual || !sistema.actual.accion || !sistema.actual.experiencia)
  {
    history.push('/home');
  }

  const continuarReservando = () => {
    history.push('/experiencias');
  }

  return (
    <div className="exito-de-experiencia-page">
      <section className="exito-section">
        <i className="exito__icon icon--check"></i>
        <h1 className="exito__titulo">¡{sistema.actual.accion === 'realizarInscripcion' ? 'Te inscribiste' : 'Tu inscripción fue cancelada'} correctamente!</h1>
      </section>
      <section className="detalle-section">
        <div className="detalle-de-experiencia__info">
          <div className="wrapper--scrollable-content__layout" >
            <div className="wrapper--scrollable-content__column" >
              <h1 className="detalle-de-experiencia__titulo">{sistema.actual.experiencia.nombre}</h1>
              {(sistema.actual.accion === 'realizarInscripcion') && (sistema.actual.costo !== 0) &&
              <div className="exito__creditos">
                <span className="exito__costo">{sistema.actual.experiencia.costo} creditos</span>
                <span className="exito__leyenda">Te quedan <span className="bold">{usuario.creditos.balance}</span> créditos</span>
              </div>}
            </div>
            <div className="wrapper--scrollable-content__column detalle-de-experiencia__data" >
              <DetalleConIconoYTexto className="detalle-de-experiencia__fecha" icono="clock" texto={sistema.actual.experiencia.fecha} />
              <DetalleConIconoYTexto className="detalle-de-experiencia__duracion" icono="" texto={"Duración: " + sistema.actual.experiencia.duracion} />
              <DetalleConIconoYTexto className="detalle-de-experiencia__ubicacion" icono="map-pin" texto={sistema.actual.experiencia.ubicacion} />
            </div>
          </div>
        </div>
        {(sistema.actual.accion === 'realizarInscripcion') && <>
          <span className="exito__ademas">Además te enviamos toda la información a tu correo de contacto</span>
        </>}
        <FixedCTA onClick={continuarReservando} label="Volver al inicio" />
      </section>
    </div>
  );
}