import React, { useEffect, useState } from "react";

export default ({titulo, texto, className, icon, ...props}) => {
  const [folded, setFolded] = useState(props.folded);
  const [listHeight, setListHeight] = useState('0px');
  const unfoldMe = (event) => {
    setFolded(!folded);
  }

  useEffect(() => {
    if (folded) {
      setListHeight('0px');
    } else {
      setListHeight('auto');
    }
  }, [folded]);

  useEffect(() => {
    setFolded(props.folded);
  }, [props.folded]);

  return (
    <div className={"selector__wrapper" + (folded ? ' is-folded' : ' is-unfolded') + (className ? ' '+className : '')}>
      <button className={"selector "} onClick={unfoldMe}>
        <h1 className="selector__titulo">{titulo}</h1>
        <div className="selector__folded"><img src="/img/chevron.svg" alt="" /></div>
      </button>
      <div className="selector-list" style={{height: listHeight}}>
        <div className="selector-list-header">
          <h1 className="selector-list__titulo">{texto}</h1>
          {props.children}
        </div>
      </div>
    </div>
  );
}