import React from 'react';

export default ({item}) => {
  return (
    <button className="button--reservation-room" onClick={item.accion} >
      {item.titulo && <span className="text--highlight">{item.titulo}</span>}
      {item.texto && <span className="text">{item.texto}</span>}
      <div className="icon--">
        <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 15L2 8L8 1" stroke="white" strokeWidth="1"/>
        </svg>
      </div>
      {/*<img className="icon--" src={"/img/chevron--white.svg"} alt="" />*/}
      {/*<i className="icon--chevron-right"></i>*/}
    </button>
  )
}
