import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import OficinaListItem from "../../components/OficinaListItem";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from "../../model/Sistema";

export default () => {
  const history = useHistory();
  const [oficinas, setOficinas] = useState(sistema.oficinas);

  const setOficina = (oficina) => {

    if (!sistema.actual && localStorage.actual) {
      sistema.actual = JSON.parse(localStorage.actual);
    }

    if (!sistema.actual) {
      sistema.actual = {};
    }

    sistema.actual.accion = 'reservarMiOficina';
    sistema.actual.oficina = oficina;
    sistema.actual.reservas = [];
    sistema.actual.fechaHasta = null;
    if (sistema.actual.espacio) {
      delete(sistema.actual.espacio);
    }
    if (sistema.actual.sector) {
      delete(sistema.actual.sector);
    }
    if (sistema.actual.turno) {
      delete(sistema.actual.turno);
    }
    if (sistema.actual.mesa) {
      delete(sistema.actual.mesa);
    }
    if (sistema.actual.puesto) {
      delete(sistema.actual.puesto);
    }
    if (sistema.actual.fecha) {
      delete(sistema.actual.fecha);
    }

    localStorage.actual = JSON.stringify(sistema.actual);
    history.push('/mioficina');
  }


  if (!sistema.oficinas || (sistema.oficinas.length === 0)) {
    history.goBack();
  } else {
    if (sistema.oficinas.length === 1) {
      setOficina(sistema.oficinas[0]);
    }
  }


  return (
    <div className="seleccion-de-oficina-page">
      <TituloConBack titulo="Seleccioná tu oficina" />
      <ul className="seleccion-de-oficina__listado">
        {oficinas && oficinas.map((item, index) =>
          <li key={index} className="seleccion-de-oficina__listado-item" >
            <button className="seleccion-de-oficina__listado-control" onClick={() => setOficina(item)}>
              <OficinaListItem nombre={item.nombre} espacio={item.espacio.nombre} sector={item.sector.nombre}/>
              {/* <div><img src="/img/chevron.svg" alt=""/></div> */}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}