import React, { useEffect, useState } from "react";
import BotonTurno from "../../components/BotonTurno";
import Calendario from "../../components/Calendario";
import FixedCTA from "../../components/FixedCTA";
import Selector from "../../components/Selector";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from '../../model/Sistema';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import APIManager from '../../managers/APIManager';

export default function ReservaDePosicion({usuario, ...props }) {
  const history = useHistory();
  const [turnos, setTurnos] = useState([
    {id: 1, codigo: 'MORNING', titulo: "Mañana", descripcion: "8:30 a 14:30 hs", costo: 2},
    {id: 2, codigo: 'AFTERNOON', titulo: "Tarde", descripcion: "14:30 a 20:30 hs", costo: 2},
    {id: 3, codigo: 'DOBLE', titulo: "Doble Jornada", descripcion: "8:30 a 20:30 hs", costo: 3}
  ]);

  const [turno, setTurno] = useState(sistema.actual && sistema.actual.turno ? (turnos[sistema.actual.turno.id-1] ?? turnos.find(item => item.codigo === turno)) : turnos[0]);
  const [espacio, setEspacio] = useState(sistema.actual && sistema.actual.espacio ? sistema.actual.espacio : sistema.espacio);
  const [espacios, setEspacios] = useState(sistema.espacios);
  const [sector, setSector] = useState(sistema.actual && sistema.actual.sector ? sistema.actual.sector : null);
  const [sectores, setSectores] = useState((!!sistema.espacio && !!sistema.espacio.sectores) ? sistema.espacio.sectores : []);
  const [sectoresFolded, setSectoresFolded] = useState(true);
  const [fecha, setFecha] = useState(sistema.actual && sistema.actual.fecha ? sistema.actual.fecha : new Date());
  const [sectoresLoading, setSectoresLoading] = useState(false);
  const [saldo, setSaldo] = useState(usuario.creditos.balance);

  const verImagenes = sistema.verFotosDeSedes;

  useEffect(() => {
    if ((!!espacio && !!espacio.activo && !!espacio.horario && !!espacio.horario.apertura && !!espacio.horario.cierre)) {
      sistema.actual.espacio = espacio;
      // Tomamos el horario del espacio para mostrar en los turnos
      let apertura = new Date();
      apertura.setHours(espacio.horario.apertura.substr(0, 2));
      apertura.setMinutes(espacio.horario.apertura.substr(3, 2));
      apertura.setSeconds(0);
      apertura = moment(apertura);

      let cierre = new Date();
      cierre.setHours(espacio.horario.cierre.substr(0, 2));
      cierre.setMinutes(espacio.horario.cierre.substr(3, 2));
      cierre.setSeconds(0);
      cierre = moment(cierre);

      let medio = moment.duration(cierre.diff(apertura)) / 2;
      medio = moment(apertura).add(medio);

      switch (medio.minutes()) {
        case 15:
          medio.minutes(0);
          break;
        case 45:
          medio.minutes(30);
          break;
        default:
      }

      const turnosActualizados = [
        { id: 1, codigo: 'MORNING', titulo: "Mañana", descripcion: apertura.format('H:mm') + ' a ' + medio.format('H:mm') + ' hs', costo: 2 },
        { id: 2, codigo: 'AFTERNOON', titulo: "Tarde", descripcion: medio.format('H:mm') + ' a ' + cierre.format('H:mm') + ' hs', costo: 2 },
        { id: 3, codigo: 'DOBLE', titulo: "Doble Jornada", descripcion: apertura.format('H:mm') + ' a ' + cierre.format('H:mm') + ' hs', costo: 3 }
      ];
      setTurnos(turnosActualizados);
      if (!!sistema.actual.turno) {
        const turnoAnterior = turnosActualizados.find(item => item.id === sistema.actual.turno.id);
        if (!!turnoAnterior) {
          setTurno(turnoAnterior);
        }
      }
      // if (!espacio.sectores) {
        // El espacio seleccionado no tiene los sectores cargados
        // procedemos a cargarlos
        setSectoresLoading(true);
        APIManager.getSectores(espacio, fecha, turno.codigo, (lista) => {
          const espacioIndex = sistema.espacios.findIndex(item => item.id === espacio.id);
          espacio.sectores = lista;
          // Actualizamos los espacios del sistema (para futuras rondas)
          sistema.espacios[espacioIndex] = espacio;
          sistema.espacio = espacio;


          // Actualizamos el estado
          const sectorIndex = espacio.sectores.findIndex(item => item.id === (!!sector ? sector.id : null));

          setEspacios(sistema.espacios);
          setEspacio(sistema.espacio);
          setSectores(espacio.sectores);
          setSector(espacio.sectores && (espacio.sectores.length > 0) ? ((sectorIndex > -1) ? espacio.sectores[sectorIndex] : espacio.sectores[0]) : null);
          setSectoresFolded(true);
          setSectoresLoading(false);
        });
      // }
      setSectores(espacio.sectores);
      setSector(espacio.sectores && (espacio.sectores.length > 0) && !sector ? espacio.sectores[0] : (sistema.actual && sistema.actual.sector ? sistema.actual.sector : null));
      setSectoresFolded(true);
    }
  }, [espacio]);

/*
  useEffect(() => {
    // Actualizacion de sectores con disponibles
    setSectoresLoading(true);
    APIManager.getSectores(espacio, fecha, turno.codigo, (lista) => {
      const espacioIndex = sistema.espacios.findIndex(item => item.id === espacio.id);
      espacio.sectores = lista;
      // Actualizamos los espacios del sistema (para futuras rondas)
      sistema.espacios[espacioIndex] = espacio;
      sistema.espacio = espacio;

      // Actualizamos el estado
      setEspacios(sistema.espacios);
      setEspacio(sistema.espacio);
      setSectores(espacio.sectores);
      setSector(espacio.sectores && (espacio.sectores.length > 0) ? (!!sector ? espacio.sectores.find(item => item.id === sector.id) : espacio.sectores[0]) : null);
      setSectoresFolded(true);
      setSectoresLoading(false);
    });
  }, [fecha, turno]);
 */

  /* TODO: Posibles calendarios pre-definidos:
    1. react-calendar (basico)
    2. react-big-calendar (estilo google calendar)
  */

  const fechaDisponible = (fechaAVerificar) => {
    // Devuelve si la fecha esta disponible
    const hoy = new Date();
    hoy.setHours(0,0,0,0);
    const aMoment = moment(fechaAVerificar);

    // Preliminar: Devolvemos true si es hoy, futuro y no es domingo
    //return aMoment.isSameOrAfter(hoy) && (aMoment.day() !== 0) /*domingo*/

    // Final: Devolvemos true si es hoy, futuro y dia de la semana permitido para el espacio
    return aMoment.isSameOrAfter(hoy) && (espacio.horario.semana[aMoment.day()]);
  }

  const sectorSeleccionado = () => {
    return !!sector;
  }

  const seleccionarEscritorio = () => {
    if (!sistema.actual) {
      if (!localStorage.actual) {
        sistema.actual = {};
      } else {
        sistema.actual = JSON.parse(localStorage.actual);
      }
    }
    sistema.actual.accion = 'reservarEscritorio';
    sistema.actual.fecha = fecha;
    sistema.actual.espacio = espacio;
    if (!sistema.actual.sector || !sector || (sistema.actual.sector.id !== sector.id)) {
      // Cambió el sector, limpiamos mesa
      sistema.actual.mesa = null;
    }
    sistema.actual.sector = sector;
    if (!sistema.actual.mesa) {
      // Cambió la mesa, limpiamos puestos
      sistema.actual.puesto = null;
      sistema.actual.puestos = [];
    }
    sistema.actual.turno = turno;
    sistema.actual.costo = turno.costo;
    sistema.actual.costoPorPuesto = turno.costo;
    localStorage.actual = JSON.stringify(sistema.actual);
    history.push('/escritorio');
  }

  const comprarCreditos = () => {
    history.push('/creditos');
  }

  const showInfoDeSector = (sector) => {
    return !!sector ? sector.disponible + ' disponibles' : null;
  }

  useEffect(() => {
    if (!!turno && !!usuario) {
      setSaldo(usuario.creditos.balance - turno.costo);
    }
  }, [turno, usuario.creditos.balance]);

  useEffect(() => {
    if (!espacio || !espacio.activo || !espacio.horario || !espacio.horario.apertura || !espacio.horario.cierre) {
      // No hay data habil del espacio
      history.push('/');
    } else {
      props.setSeccion('/reservas');
    }
  }, []);

  return (
    (!!espacio && !!espacio.activo && !!espacio.horario && !!espacio.horario.apertura && !!espacio.horario.cierre) &&
    <div className={"posicion-page"}>
      <TituloConBack titulo="Reserva de posición" />
      <div className="wrapper--scrollable-content__layout">
        <div className="wrapper--scrollable-content__column">
          <div className='titulo'>Eligí el día, sede y sector</div>
          <Selector titulo="Sede" selectedItem={espacio} items={espacios} icon="" onChange={setEspacio} folded={true}/>
          <div style={{display: 'none'}}>opciones de dia/repeticion</div>
          <Selector loading={sectoresLoading} showInfo={null} leyendaSinItems="Sin mesas disponibles" titulo="Sector" selectedItem={sector} items={sectores} icon="" onChange={setSector} folded={sectoresFolded} />
          {!!verImagenes && <button className="control--info-de-salas" onClick={() => {history.push('/infodesectores')}}>Ver información de sectores</button>}
          <Calendario {...props} fecha={fecha} type={props.isPortrait ? 'week' : 'month'} onChange={setFecha} esFechaDisponible={fechaDisponible}/>
        </div>
        <div className="wrapper--scrollable-content__column">
          <div className='titulo'>Eligí el turno</div>
          <div>
            {turnos.map((item) => <BotonTurno key={item.id} turno={item} checked={!!turno && (item.id === turno.id)} clicked={setTurno} />)}
          </div>
        </div>
      </div>
      {(saldo < 0) && <FixedCTA className="CTA__comprar" titulo={turno.costo + " Créditos"} leyendaPre="Créditos " leyendaBold="insuficientes" leyendaPost="" label="Comprar créditos" onClick={comprarCreditos} />}
      {(saldo >= 0) && <FixedCTA disabled={!fechaDisponible(fecha) || !sectorSeleccionado() } titulo={turno.costo + " Créditos"} leyendaPre="Te quedarán" leyendaBold={usuario.creditos.balance - turno.costo} leyendaPost="créditos" label="Continuar" onClick={seleccionarEscritorio} />}
    </div>
  );
}
