import React, { useEffect, useState } from "react";

export default ({salas, horarios, usuario, ...props}) => {

  return (
    <div className="reservas-de-salas">


      <div className="wrapper--reservas-de-salas__layout">
        <ol className="horarios-disponibles">
          {horarios && horarios.map((hora, index) =>
            <li key={index} className="horarios-disponibles__slot-horario"><span>{hora.nombre}</span></li>
          )}
        </ol>

        <div className="wrapper--reservas-de-sala__layout">

          {salas && (salas.length > 0) && salas.map((sala, index) =>
            <ul key={index} className={"reservas-de-sala sala--" + index}>
              <li className="reservas-de-sala__nombre-de-sala">
                <span>{sala.nombre}</span>
              </li>
              {sala.reservas && (sala.reservas.length > 0) && sala.reservas.map((reserva, index) =>
                <li key={index} className={"reserva-de-sala duracion-en-slots--" + reserva.duracionSlots + " inicia-en-slot--" + reserva.inicioSlot}>
                  <div className="reserva-de-sala__usuario">
                    {reserva.usuarioAsignado.imagen
                      ? <img src={reserva.usuarioAsignado.imagen} className="reserva-de-sala__avatar-usuario" alt="Avatar del usuario" title={reserva.usuarioAsignado.nombres + ' ' + reserva.usuarioAsignado.apellidos} />
                      : <div className="reserva-de-sala__avatar-usuario"><span className="reserva-de-sala__avatar-usuario__text" title={reserva.usuarioAsignado.nombres + ' ' + reserva.usuarioAsignado.apellidos}>{reserva.usuarioAsignado.nombres[0]+reserva.usuarioAsignado.apellidos[0]}</span></div>
                    }
                  </div>
                </li>
              )}
            </ul>
          )}
        </div>

      </div>

    </div>
  );
}