import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sistema } from '../../model/Sistema';
import { getUIData, sendRequest } from '../../lib/serverAPI';
import TituloConBack from '../../components/TituloConBack';
import FixedCTA from '../../components/FixedCTA';
import DetalleConIconoYTexto from '../../components/DetalleConIconoYTexto';

export default ({ usuario, ...props }) => {

  const history = useHistory();
  let { id } = useParams();
  const [beneficio, setbeneficio] = useState(null);

  if (!sistema.actual.id && id) {
    sistema.actual.id = id;
  }
  if (!sistema.actual.id) {
    history.push('/beneficios');
  }

  useEffect(() => {
    getUIData('getBeneficio', {id: sistema.actual.id}, setbeneficio);
  }, []);

  const registroRealizado = (response) => {
    sistema.actual.beneficio = beneficio;
    sistema.usuario.creditos.balance -= response.data.costo;
    sistema.saveActual();
    history.push('/exitobeneficio');
  }

  const registrarme = (event) => {
    sistema.actual.accion = 'realizarInscripcion';
    confirmarClick(event);
  }

  const cancelarme = (event) => {
    sistema.actual.accion = 'cancelarInscripcion';
    confirmarClick(event);
  }

  const confirmarClick = (event) => {
    const postData = {
      eventoID: beneficio.id
    };
    sendRequest('post','eventos',sistema.actual.accion, postData, registroRealizado);
  }

  const irAlSitio = (url) => {
    window.open(url, '_BLANK');
  }

  props.setSeccion('/beneficios');

  const inscripcionHabilitada = ((usuario.status === 'approved') && beneficio && (usuario.creditos.balance > beneficio.costo));

  return (beneficio &&
    <div className="detalle-de-beneficio">
      <TituloConBack titulo={beneficio.empresa}>
        <div className="detalle-de-beneficio__icons">
          {/* Botones de compartir y guardar como favorito
          <button className="detalle-de-beneficio__control" ><DetalleConIconoYTexto icono="share-alt" texto="Compartir" /></button>
          <button className="detalle-de-beneficio__control"><DetalleConIconoYTexto icono={<img src="/img/icon--heart-black.svg" alt="" />} texto="Guardar" /></button>
          */}
        </div>
      </TituloConBack>
      <div className="wrapper--scrollable-content">
        <div className="scrollable-content__row image-row">
          <div className="detalle_de_beneficio__imagen-wrapper" style={{backgroundImage: "url(" + beneficio.logo + ")"}}></div>
        </div>
        <div className="scrollable-content__row">
          <div className="scrollable-content__column column--primary">
            <div className="detalle-de-beneficio__showcase">
              <div className="detalle-de-beneficio__detalles">
                <h1 className="detalle-de-beneficio__titulo">{beneficio.nombre}</h1>
                <h2 className="detalle-de-beneficio__subtitulo">{ beneficio.empresa }</h2>
                <p className="detalle-de-beneficio__descripcion">{beneficio.descripcion}</p>
              </div>
            </div>
          </div>
          <div className="scrollable-content__column column--secondary">
            <div className="detalle-de-beneficio__info">
              {/* <DetalleConIconoYTexto className="detalle-de-beneficio__fecha" icono={<img className="icon--clock" src="/img/icon--time-grey.svg" alt=""></img>} texto={beneficio.fecha} /> */}
              {/* <DetalleConIconoYTexto className="detalle-de-beneficio__duracion" icono="" texto={"Duración: " + beneficio.duracion} /> */}
              {beneficio.places &&
                <DetalleConIconoYTexto className="detalle-de-beneficio__ubicacion" icono={<img className="icon--map-pin" src="/img/icon--location-grey.svg" alt=""></img>} texto={beneficio.places} />}
              {beneficio.descuento &&
                <DetalleConIconoYTexto className="detalle-de-beneficio__descuento" icono={<img className="icon--info" src="/img/icon--info-warning.svg" alt=""></img>} texto={beneficio.descuento} />}
            </div>
          </div>
        </div>
        <div className="scrollable-content__row">
          {beneficio.web &&
            <button className="control--link-web" onClick={() => irAlSitio(beneficio.web)}>Ir al sitio</button>}
            {/* <DetalleConIconoYTexto className="detalle-de-beneficio__link" icono={<img className="icon--info" src="/img/icon--info-warning.svg" alt=""></img>} texto={<a className="detalle__texto" href={beneficio.web} >Link</a>} /> */}
        </div>


      </div>
    </div>
  )
}