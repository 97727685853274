import React from 'react';
import BeneficioCard from '../BeneficioCard';

export default ({items, ...props}) => {

  return (
    !items || (items.length === 0) ? null :
      <ul className="beneficios-list">
        {items.map((item, index) => (
          <li key={item.id} className={"beneficio"}>
            <BeneficioCard {...item} />
          </li>
        ))}
      </ul>
  );
}