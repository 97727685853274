import React from "react";
import DetalleConIconoYTexto from "../DetalleConIconoYTexto";

export default ({nombre, cantidadDeCreditos, valor, descripcion, propiedades, checked, onClick, ...props}) => {

  return (
    <button className={"paquete-de-creditos" + (checked ? ' is-checked' : '')} onClick={onClick} >
      <div className="paquete-de-creditos-textos">
        <h1 className="paquete-de-creditos-textos__titulo">{nombre}</h1>
        <h1 className="paquete-de-creditos-textos__creditos">{cantidadDeCreditos}</h1>
        <h1 className="paquete-de-creditos-textos__creditos">créditos</h1>
        <h2 className="paquete-de-creditos-textos__valor">{valor && (valor > 0) ? '$' + valor : ''}</h2>
        <p className="paquete-de-creditos-textos__descripcion">{descripcion}</p>
        {propiedades && (propiedades.length > 0) && propiedades.map((item, index) => <DetalleConIconoYTexto key={index} />)}
      </div>
      <div className="paquete-de-creditos-icon"><i className="icon--check"></i></div>
      <div className="paquete-de-creditos-control">Lo quiero</div>
    </button>
  );
}