import React, { useEffect, useState } from 'react';
import Experiencia from '../../components/Experiencia';

import { getUIData } from '../../lib/serverAPI';

export default ({eventos, ...props}) => {
  const [items, setItems] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriaActual, setCategoriaActual] = useState([]);

  useEffect(() => {
    function armarShowcase(data) {
      setCategorias(data.categorias);
      setItems(data.experiencias);
      if (data.categorias.length > 0)
      {
        setCategoriaActual(data.categorias[0]);
      }
    }

    // TODO: ver como seria "traer mas eventos" (a partir de los 8 iniciales)
    getUIData('getExperienciasShowcase', {limit: 100}, armarShowcase);
  }, [])

  return (
    items.length === 0 ? null :
    <section className="experiences-showcase">
      <h1 className="experiences-showcase__title">Nuestras Experiencias</h1>
      <ul className="experiences-categories-list">
        {categorias && (categorias.length > 0) && categorias.map((item) => (
          <li key={item.id} className={"experience-type" + (item === categoriaActual ? ' is-active' : '')}>
            <button className="experience-type__selector">{item.nombre}</button>
          </li>
        ))}
      </ul>
      <ul className="experiences-list">
        {items.map((item, index) => (
          // (!props.isPortrait || (index < 4)) && // No limitamos la cantidad para mobile
          <li key={item.id} className={"experience"}>
            <Experiencia {...item} {...props} />
          </li>
        ))}
      </ul>
      {/*
      <div className="experiences-showcase__controls">
        <button className="experiences-showcase__more-experiences">
          M&aacute;s eventos como estos
          <i className="icon--chevron-right"></i>
        </button>
      </div>
      */}
    </section>
  );
}