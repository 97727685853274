import React from 'react';

export default ({fecha}) => {
  const diaDeLaSemana = new Date(fecha).getUTCDay();
  const diaDelMes = new Date(fecha).getUTCDate();

  const diasDeLaSemana = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];

  return (
    <div className="event-item__date">
      <span className="event-item__day">{diasDeLaSemana[diaDeLaSemana].substr(0,3)}</span>
      <span className="event-item__date-number">{diaDelMes}</span>
    </div>
  )
}