import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BotonCalendario from "../../components/BotonCalendario";
import OficinaListItem from "../../components/OficinaListItem";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from "../../model/Sistema";
import { getUIData } from '../../lib/serverAPI';
import moment from 'moment';
import 'moment/locale/es';
import FixedCTA from "../../components/FixedCTA";

export default ({usuario, ...props}) => {
  moment.updateLocale('es', {
    week: {
      dow: 0
    }
  })

  const [oficina, setOficina] = useState(sistema.actual && sistema.actual.oficina ? sistema.actual.oficina : null);
  const history = useHistory();
  const [calendario, setCalendario] = useState([]);
  const [fechaHasta, setFechaHasta] = useState(sistema.actual && sistema.actual.fechaHasta ? sistema.actual.fechaHasta : moment(new Date).locale('es').endOf('week'));
  const [conReservas, setConReservas] = useState(sistema.actual && sistema.actual.reservas && (sistema.actual.reservas.length > 0) ? true : false);

  if ((!sistema.actual || !sistema.actual.oficina) && localStorage.actual) {
    sistema.actual = JSON.parse(localStorage.actual);
  }
  if (!sistema.actual || !sistema.actual.oficina)
  {
    history.push('/oficina');
  }

  useEffect(() => {
    if (oficina) {
      const fechaDesde = moment(new Date()).locale('es');
      getUIData('getOficinasParaReserva',{oficinaID: oficina.id, espacioID: oficina.espacio.id, fechaDesde: fechaDesde, fechaHasta: fechaHasta},(response) => {
        actualizarCalendario(response.calendar);
//        setCalendario(response.calendar);
      });
    }
  },[oficina, fechaHasta]);

  const miReservaDe = (item) => {
    const miReserva = {fecha: item.fecha, first_name: usuario.nombres, last_name: usuario.apellidos, oficina_id: oficina.id, usuario: usuario};
    miReserva.usuario.imagen = miReserva.usuario.avatar;
    return miReserva;
  }

  const continuar = () => {
    sistema.actual.oficina = oficina;
    sistema.actual.espacio = oficina.espacio;
    sistema.actual.sector = oficina.sector;
    sistema.actual.costo = 0;
    sistema.actual.fechaHasta = fechaHasta;
    localStorage.actual = JSON.stringify(sistema.actual);
    history.push('/confirmar');
  }

  const actualizarCalendario = (calendario) => {
    setCalendario(calendario.map(item => {
      const miItem = sistema.actual.reservas.find(calItem => calItem.fecha === item.fecha);
      item.checked = !!miItem;
      if (miItem) {
        item.miReserva = miReservaDe(miItem);
      } else {
        if (item.miReserva && (item.status === 'disponible')) {
          delete(item.miReserva);
        }
      }

      return item;
    }));
  }

  const itemSeleccionado = (item) => {
    if (!item.checked) {
      sistema.actual.reservas.push(item);
    } else {
      sistema.actual.reservas = sistema.actual.reservas.filter(miItem => miItem.fecha !== item.fecha);
    }
    actualizarCalendario(calendario);
    setConReservas(sistema.actual.reservas.length > 0);
  }

  const agregarSemana = () => {
    setFechaHasta(moment(fechaHasta).locale('es').add(7,'days'));
  }

  return (<div className="mi-oficina-page">
    <TituloConBack titulo="Reserva de Oficina"/>
    <OficinaListItem nombre={oficina.nombre} espacio={oficina.espacio.nombre} sector={oficina.sector.nombre} />
    <section className="mi-oficina-calendario">
      <h2 className="mi-oficina-calendario__titulo">Elegí los días</h2>
      <p className="mi-oficina-calendario__parrafo">(La reserva corresponde al día completo en el horario de la sede)</p>
      <ol className="mi-oficina-listado">
        {calendario && (calendario.length > 0) && calendario.map((item, index) =>
          <li key={index} className="mi-oficina-listado__item">
            <BotonCalendario fecha={item.fecha} status={item.status} checked={item.checked} reservas={item.reservas} miReserva={item.miReserva} onClick={() => {itemSeleccionado(item)}} />
          </li>
        )}
      </ol>
      <button className="mi-oficina-control" onClick={agregarSemana}><span className="mi-oficina-control__text">Ver próxima semana</span><img className="mi-oficina-control__icon" src="/img/chevron.svg" alt="" /></button>
    </section>
    <FixedCTA disabled={!conReservas} label="Continuar" onClick={continuar} />
  </div>);
}