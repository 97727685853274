import React from 'react';
import { useHistory } from 'react-router-dom';
import { sistema } from '../../model/Sistema';
import moment from 'moment';
import 'moment/locale/es';
import Moment from 'react-moment';

export default ({id, fecha, titulo, ubicacion, imagen, onClick, ...props}) => {
  const history = useHistory();

  const timestamp = fecha;
  const dateStr = timestamp.substr(0,10);
  const gmtStr = timestamp.substr(20);
  const timeStr = timestamp.substr(11,5);

  const fechaStr = new Date(dateStr+"GMT"+gmtStr).toDateString();
  const horaStr = " " + timeStr + "hs";

  const onOpenExperiencia = () => {
    sistema.actual.id = id;
    history.push("/experiencia/"+id);
  }

  return (
  <>
    <div className="wrapper--experience__image">
      <img onClick={onClick ? onClick : onOpenExperiencia} className="experience__image" src={imagen} alt={titulo} />
    </div>
    <div onClick={onClick ? onClick : onOpenExperiencia} className="experience__details">
      <div className="experience__date-and-time">
        <span className="experience__date"><Moment format="ddd D [de] MMMM" locale="es">{fecha}</Moment></span>
        <span className="experience__time">{horaStr}</span>
      </div>
      <h1 className="experience__title">{titulo}</h1>
      <div className="experience__location">
        {/*<i className="icon--map-pin"></i>*/}
          <img className="icon--map-pin" src="/img/icon--location-grey.svg" alt=""></img>
          <img className="icon--map-pin hover" src="/img/icon--location-white.svg" alt=""></img>
        <span>{ubicacion}</span>
      </div>
    </div>
    {/*<div className="experience__controls">
      <button className="experience__like-button">
        <img src="/img/icon--heart-white--with-shadow.svg" alt="" />
      </button>
  </div>*/}
  </>
)}