import React from 'react';
import Evento from '../EventoDeHistorial';

export default ({eventos, page, completo, nextPage, previowsPage, ...props}) => {

  return (
    <section className="my-week">
      <ol className="event-list">
        {(page > 1) && <li>
          <span className="control--ver-mas">{'mostrando ' + eventos.length + ' registros'}
            <button className='button--icon-only button--no-background' onClick={previowsPage}>
              <img className="control--ver-menos__icon" alt="ver más" src="img/chevron--orange.svg"></img>
            </button>
          </span>
        </li>}
        {eventos && (eventos.length > 0) && eventos.map((item, index) => (
          <li key={index} className={"event-item" + (item.tipo === 'Evento' ? ' event-type--purple' : '')}>
            <Evento evento={item} />
          </li>
        ))}
        {!!completo && <li>
          <span className="control--ver-mas">{'mostrando ' + eventos.length + ' registros'}
            <button className='button--icon-only button--no-background' onClick={nextPage}>
              <img className="control--ver-mas__icon" alt="ver más" src="img/chevron--orange.svg"></img>
            </button>
          </span>
        </li>}
      </ol>
    </section>
  );
}