import React, {useEffect, useState} from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FixedCTA from "../../components/FixedCTA";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from "../../model/Sistema";
import 'moment/locale/es';
import Moment from "react-moment";
import { sendRequest } from '../../lib/serverAPI';
import moment from "moment";
import APIManager from "../../managers/APIManager";

export default ({usuario, ...props}) => {
  const history = useHistory();

  const [titulo, setTitulo] = useState(sistema.actual && sistema.actual.evento ? ("Resumen de " + sistema.actual.evento.tipo) : '');
  // const [subtitulo, setSubtitulo] = useState(sistema.actual && sistema.actual.evento && sistema.actual.accion ? "Estás cancelando" : "Resumen de");
  const [subtitulo, setSubtitulo] = useState(sistema.actual && sistema.actual.evento ? "Resumen de" : '');
  const [cancelarHabilitado, setCancelarHabilitado] = useState(true);
  const [puedeCancelar, setPuedeCancelar] = useState(true);
  const [asistio, setAsistio] = useState(true);
  const [cancelando, setCancelando] = useState(false);
  const [loading, setLoading] = useState(true);

  if ((!sistema.actual || !sistema.actual.evento) && localStorage.actual) {
    sistema.loadActual();

  }
  if (!sistema.actual || !sistema.actual.evento)
  {
    history.push('/home');
  }

  useEffect(() => {
    sistema.actual.costo = 0; // Por ahora no se devuelven creditos
    delete (sistema.actual.accion);
    delete (sistema.actual.cancelando);

  }, []);

  const descripcion = () => {
    switch (sistema.actual.accion) {
      case 'reservarEscritorio':
        return 'puesto ' + sistema.actual.puesto.id;
      case 'reservarSala':
        return sistema.actual.sala.nombre;
      default:
        return '';
    }
  }

  const confirmar = () => {
    sendRequest('post', 'reservas',sistema.actual.accion, sistema.actual, (response) => {
      if (response.success) {
        sistema.actual.reserva = response.data;
        // Calculamos la devolucion total
        sistema.actual.costo = 0;
        if (sistema.actual.reserva.transacciones) {
          sistema.actual.reserva.transacciones.forEach(devolucion => {
            sistema.actual.costo += parseFloat(devolucion.monto);
          });
        }

        sistema.usuario.creditos.balance += sistema.actual.costo;
        history.push('/exito');
      } else {
        // Hay errores
      }
    })

  }

  const detalle = () => {
    switch (sistema.actual.evento.subtipo) {
      case 'ESCRITORIO':
          return <>
          el día <span><Moment format="dddd D [de] MMMM" locale="es">{sistema.actual.evento.fecha}</Moment></span><br/>
          turno <span>{sistema.actual.evento.turno}</span>
        </>;
      case 'OFICINA':
        return <>
        el día <span><Moment format="dddd D [de] MMMM" locale="es">{sistema.actual.evento.fecha}</Moment></span><br/>
      </>;
      case 'SALA':
      case 'CALLROOM':
        return <>
          el día <span><Moment format="dddd D [de] MMMM" locale="es">{sistema.actual.evento.fecha}</Moment></span><br/>
          a las <span>{sistema.actual.evento.turno}</span>
        </>;

      default:
        return null;
    }
  }

  const detalleAviso = () => {
    switch (sistema.actual.evento.subtipo) {
      case 'ESCRITORIO':
        return <>
          Se realiza devolución de créditos según la anticipación de cancelación o reprogramación.<br />
          <ul>
            <li>Con más de 24hs, se devuelve el 100%</li>
            <li>Entre 12hs y 24hs, se devuelve el 50%</li>
            <li>Con menos de 12hs, no hay devolución</li>
          </ul>
        </>;
      case 'SALA':
        return <>
          Se realiza devolución del 100% de los créditos solo si se cancela con una anticipación mayor o igual a 4 hs.<br/>
        </>;

      default:
        return null;
    }
  }

  const subtipoString = (subtipo) => {
    switch (subtipo) {
      case 'ESCRITORIO':
        return 'PUESTO';
      case 'OFICINA':
        return 'puesto en tu OFICINA';
      default:
        return sistema.actual.evento.subtipo;
    }

  }

  useEffect(() => {
    const now = moment(new Date());
    sistema.actual.evento.inicio = moment(sistema.actual.evento.inicio);
    sistema.actual.evento.fin = moment(sistema.actual.evento.fin);

    switch (sistema.actual.evento.subtipo) {
      case 'ESCRITORIO':
        const medio = new Date((sistema.actual.evento.fin - sistema.actual.evento.inicio) / 2);
        verificarAsistencia(sistema.actual.evento?.usuario?.id ?? usuario.id, sistema.actual.evento.espacio_id, sistema.actual.evento.fecha);
        switch (sistema.actual.evento.turno) {
          case 'Tarde':
            sistema.actual.evento.inicio.add(medio, 'milliseconds');
            break;
          case 'Mañana':
            sistema.actual.evento.fin.add(-medio, 'milliseconds');
            break;
          default:
        }
        break;
      default:
        setLoading(false);
    }
    const fecha = sistema.actual.evento.fin;
    setPuedeCancelar(fecha > now);
  }, []);

  const verificarAsistencia = (usuarioID, espacioID, fecha) => {
    sendRequest('post', 'usuario', 'verificarAsistencia', { usuarioID, espacioID, fecha }, response => {
      if (response.success) {
        setAsistio(response.data);
      }
      setLoading(false);
    })
  }

  const showCancelar = () => {
    if (!puedeCancelar) {
      history.goBack();
    } else {
      sistema.actual.accion = 'cancelar'+sistema.actual.evento.tipo;
      setTitulo("Cancelación de " + sistema.actual.evento.tipo);
      setSubtitulo("Estás cancelando");
      setCancelando(true);
      // setTimeout(() => {setCancelarHabilitado(true)}, 2000);
    }
  }

  const ctaLabel = () => {
    return puedeCancelar ? "Cancelar " + sistema.actual.evento.tipo : "Volver";
  }

  const turnos = [
    {id: 1, codigo: 'MORNING', titulo: "Mañana", descripcion: "8:30 a 14:30 hs", costo: 2},
    {id: 2, codigo: 'AFTERNOON', titulo: "Tarde", descripcion: "14:30 a 20:30 hs", costo: 2},
    {id: 3, codigo: 'DOBLE', titulo: "Doble Jornada", descripcion: "8:30 a 20:30 hs", costo: 3}
  ];

  const armarReserva = (accion, callback) => {
    sendRequest('post', 'reservas', 'getReserva', { evento: sistema.actual.evento }, response => {
      sistema.actual.accion = "reservarEscritorio";
      sistema.actual.costo = 0;
      sistema.actual.costoPorPuesto = 0;
      sistema.actual.cancelando = accion;
      sistema.actual.espacio = response.data.espacio;
      sistema.actual.fecha = new Date(response.data.fecha.date);
      sistema.actual.turno = turnos.find(item => item.codigo === response.data.turno);
      sistema.actual.mesa = response.data.escritorio;
      sistema.actual.mesa.puestos = Object.values(sistema.actual.mesa.puestos);
      sistema.actual.mesa.reservas = Object.values(sistema.actual.mesa.reservas);
      sistema.actual.sector = response.data.escritorio.sector;
      sistema.actual.puestos = [{id: parseInt(response.data.estacion), status: 'TAKEN', reservas: [], usuarios: [response.data.usuarioAsignado]}];
      sistema.actual.usuario = usuario;
      sistema.actual.usuarioAsignado = response.data.usuarioAsignado;
      sistema.actual.asistio = asistio;
      localStorage.setItem('actual', JSON.stringify(sistema.actual));
      if (!!callback) {
        callback();
      }
    })
  }

  const handleModificar = (accion) => {
    switch (accion) {
      case 'transferir':
        armarReserva(accion,() => history.push('/escritorio'));
        break;
      default:
        armarReserva(accion,() => history.push('/posicion'));
    }
  }

  return ((!sistema || !sistema.actual || !sistema.actual.evento) ? null :
    <div className="cancelar-page">
      <TituloConBack titulo={titulo} />
      {!sistema.actual.accion ? null : <div className="cancelar__subtitulo">
        <span>Revisá que esté todo bien </span><span role="img" aria-label="ok">👍</span>
        <hr />
      </div>}
      {!loading &&
        <>
          <p className={"cancelar__detalle" + (sistema.actual.accion ? " alerta" : "")}>
            <span className="cancelar__detalle-subtitulo">{subtitulo}</span><br/>
            {sistema.actual.evento.titulo}<br />
            {!!usuario && !!sistema.actual.evento.usuario && (!sistema.actual.evento.usuario.id !== usuario.id) && <>
              Asignada a <span className="detalle-usuario-asignado">{sistema.actual.evento.usuario.descripcion}</span><br />
            </>}
            {sistema.actual.evento.puesto && <> para el <span>puesto {sistema.actual.evento.puesto} </span><br/></>}
            {/* <span>{sistema.actual.evento.tipo} de {subtipoString(sistema.actual.evento.subtipo)} </span><br/> */}
            en <span>{sistema.actual.evento.ubicacion} </span><br/>
            {sistema.actual.espacio && <>de <span>{sistema.actual.espacio.nombre}</span><br/></>}
            {sistema.actual.sector ? <>sector <span>{sistema.actual.sector.nombre}</span><br/></> : null}
            {detalle()}
          </p>
          <p className="cancelar__aviso">{detalleAviso()}</p>
          {!!puedeCancelar && !cancelando && (sistema.actual.evento.subtipo === 'ESCRITORIO') &&
            <div className="cancelar__controles-extra">
              <button className="fixed-CTA__control" onClick={() => { handleModificar('transferir') }}><span className="fixed-CTA__label">Transferir</span></button>
              {!asistio && <button className="fixed-CTA__control" onClick={() => { handleModificar('reprogramar')}}><span className="fixed-CTA__label">Reprogramar</span></button>}
            </div>
          }
        </>
      }
      {sistema.actual.accion
        ? <FixedCTA disabled={!cancelarHabilitado} className={(sistema.actual.costo === 0) || sistema.actual.cancelando ? 'sin-leyenda' : ''}  titulo={(sistema.actual.costo !== 0) ? (sistema.actual.costo + " Créditos") : '' } leyendaPre="Te quedarán" leyendaBold={usuario.creditos.balance - sistema.actual.costo} leyendaPost="créditos" label="Confirmar" onClick={confirmar}/>
        : <FixedCTA className='sin-leyenda' label={ctaLabel()} onClick={showCancelar}>
        </FixedCTA>
      }
    </div>
  );
}