import React, { useState, useEffect } from 'react';
import AccesoRapido from '../AccesoRapido';

import { getUIData } from '../../lib/serverAPI';
import { useHistory } from 'react-router-dom';

export default ({...props}) => {

  const history = useHistory();
  const [accesos, setAccesos] = useState([]);

  useEffect(() => {
    getUIData('getAccesosRapidos', {}, data => {
      setAccesos(data);
    });
  }, [])

  const onClickPara = (acceso) => {
    switch (acceso.tipo) {
      case 'LINK':
        history.push(acceso.datos.link);
        break;
      case 'URL':
        window.open(acceso.datos.link, '_BLANK');
        break;
      default:
      break;
    }
  }

  return (
    <section className="quick-access">
      <ul className={"quick-access__list" + (((accesos && (accesos.length < 3)) || (props.usuario.status !== 'approved')) ? ' left-align' : '')}>
        {accesos ? accesos.map((item, index) => (
          // Hotfix para cuando es 'Reservas' y no deberia poder acceder
          (!(item.nombre === 'Reservas') || (props.usuario.status === 'approved')) &&
          <li key={item.id} className="quick-access__item">
            <AccesoRapido {...item} onClick={() => {onClickPara(item)}} />
          </li>
        )) : null}
      </ul>
    </section>
  );
}
