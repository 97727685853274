import React from 'react';

export default ({creditos, usuario, titulo, espacio, solicitante, aprobador, saldo, ...transaccion}) => (
  <div className="transacciones-item__details">
    <div className="transacciones-item__type">
      <span><span className="item--nombre">Motivo: </span>{ titulo }</span>
    </div>
    <div className="transacciones-item__solicitante">
      <span><span className="item--nombre">Miembro: </span>{ solicitante.nombre }</span>
    </div>
    {/* <div className="transacciones-item__aprobador">
      <span>{ solicitante.nombre }</span>
    </div> */}
    <div className="transacciones-item__espacio">
      <span><span className="item--nombre">Espacio: </span>{ espacio.nombre }</span>
    </div>
    <div className="transacciones-item__credits">
      <span><span className="item--nombre">Monto: </span>{ (creditos > 0 ? '+' : '') + creditos + 'cr'}</span>
    </div>
    <div className="transacciones-item__saldo">
      <span><span className="item--nombre">Saldo: </span>{ saldo }</span>
    </div>
    {/* <div className="wrapper--second-row">
      <div className="event-item__time">
        <img className="icon--clock" src="/img/icon--time-grey.svg" alt=""></img>
        <span className="event-item__time--text">{turno}</span>
      </div>
      <div className="event-item__place">
        <img className="icon--map-pin" src="/img/icon--location-grey.svg" alt=""></img>
        <span className="event-item__place--text">{ubicacion}</span>
      </div>
    </div> */}
    {/* <div className="wrapper--third-row">
      <div className="event-item__extra">
        <span className="event-item__credits--text">{(!!creditos ? -creditos : 0) + ' cr'}</span>
        <span className="event-item__user--text">{!!usuario ? usuario.descripcion : ''}</span>
      </div>
    </div> */}
  </div>
)