import React, { useEffect, useState } from 'react';
import ReservarButton from './components/Reservar';

import { getUIData, sendRequest } from '../../lib/serverAPI';
import { useHistory } from 'react-router-dom';

export default ({titulo, filtros, usuario, ...props}) => {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const armarAcciones = (acciones) => {
      acciones.forEach((item) => {
          item.accion = (event) => {
            history.push('/'+item.pagina);
          }
      });
      setItems(acciones);
      setLoading(false);
    }
    getUIData('getMisAcciones', {filtros}, armarAcciones);
  }, [filtros, history]);

  const tituloFromStatus = (usuario) => {
    switch (usuario.status) {
      case 'pending-approval':
        if (!usuario.empresa || usuario.aceptado) {
          return 'Pendiente de aprobación';
        } else {
          return 'Pendiente de aprobación en tu empresa';
        }
      case 'pending-signature':
        return 'Firma del contrato pendiente';
      default:
        return 'Sin Membresía';
    }
  }

  const textoFromStatus = (usuario) => {
    switch (usuario.status) {
      case 'pending-approval':
        return 'Cuando recibas aprobación, podrás utilizar nuestros servicios';
      case 'pending-signature':
        return !!usuario.contratoAdminAccess ? 'Para utilizar MiMQco, debes dar de alta el contrato' : 'Contactar al administrador de tu empresa.';
      default:
        return usuario.habilitado
          ? 'Para poder utilizar nuestros servicios, necesitamos que completes la información básica de tu perfil'
          : 'Para poder utilizar nuestros servicios, debes contratar alguna membresía';
    }
  }

  return (
          <section className={"reservations" + (((usuario.status !== 'approved') && usuario.contrato) ? " alert" : "")}>
            {loading ? null : (
            usuario.habilitado && items && (items.length > 0)
            ? <>
              <h1 className="reservations__title">{titulo}</h1>

              <div className="reservations__controls">
                <ul className="reservations__controls-list">
                  {items.map((item) =>
                    <li key={item.id} className="reservations__control">
                      <ReservarButton item={item} />
                    </li>
                  )}
                </ul>
              </div>
              </>
            : <>
              <h1 className="reservations__title">{tituloFromStatus(usuario)}</h1>
              <span className="reservations__text">{textoFromStatus(usuario)}</span>
              {(usuario.status === 'new') && <div className="reservations__controls">
                <ul className="reservations__controls-list">
                  <li className="reservations__control">
                     <ReservarButton item={{accion: () => {history.push('/onboarding')}, texto: 'Completar mi perfil'}} />
                  </li>
                </ul>
              </div>}
              {!!usuario.contratoAdminAccess && (usuario.status === 'pending-signature') && <div className="reservations__controls">
                <ul className="reservations__controls-list">
                  <li className="reservations__control">
                     <ReservarButton item={{accion: () => {history.push('/firmar')}, texto: 'Firmar contrato'}} />
                  </li>
                </ul>
              </div>}
              </>
            )}
          </section>
  );
}