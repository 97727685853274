import React from 'react';

import MiSemana from '../../components/MiSemana';
import MisAcciones from '../../components/MisAcciones';
import Cover from '../Home/components/Cover';
import { sistema } from '../../model/Sistema';

export default function Reservas({...props}) {

  if (!sistema.actual) {
    if (!localStorage.actual) {
      sistema.actual = {};
    } else {
      sistema.actual = JSON.parse(localStorage.actual);
    }
  }
  // sistema.actual.fecha = null;
  delete (sistema.actual.evento);
  delete (sistema.actual.tipoDeSala);

  props.setSeccion('/reservas');

  return (
    <div className="reservas-page ">
      <Cover titulo="" slider={false} images={["/img/cover-reservas-"+ (props.isPortrait ? 'mobile' : 'desktop') + '.png']} />
      <div className="wrapper--scrollable-content__layout">
        <div className="wrapper--scrollable-content__column">
          <MiSemana titulo={"Mis Reservas"} {...props} limite={5} filtros={{ tipo: "soloReservas" }} />
        </div>
        <div className="wrapper--scrollable-content__column">
          <section className="my-events">
            <MisAcciones titulo={"¿Que querés reservar?"} filtros={{tipos: ['reservar']}} {...props} />
          </section>
        </div>
      </div>
    </div>
  );
}
