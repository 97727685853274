import React, { useEffect, useState } from "react";
import TituloConBack from "../../components/TituloConBack";
import { sistema } from '../../model/Sistema';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import APIManager from '../../managers/APIManager';
import SalaCard from "../../components/SalaCard";

export default function InfoDeSalas({usuario, ...props }) {
  const history = useHistory();

  const [espacio, setEspacio] = useState(sistema.actual && sistema.actual.espacio ? sistema.espacios.find(item => item.id === sistema.actual.espacio.id) : sistema.espacio);
  const [salas, setSalas] = useState(sistema.actual.salas ? sistema.actual.salas : []);

  useEffect(() => {
    // Hay que cargar las salas
    if (espacio) {
      if (!sistema.actual) {
        if (!localStorage.actual) {
          sistema.actual = {};
        } else {
          sistema.actual = JSON.parse(localStorage.actual);
        }
      }
      sistema.actual.espacio = espacio;

      APIManager.getList('salas', { espacioID: espacio.id, activas: true, tipo: sistema.actual.tipoDeSala }, (response) => {
        setSalas(response.data.listado);
      });
    }
  },[espacio]);


  const seleccionarSala = (salaSeleccionada) => {
    if (!sistema.actual) {
      if (!localStorage.actual) {
        sistema.actual = {};
      } else {
        sistema.actual = JSON.parse(localStorage.actual);
      }
    }
    sistema.actual.sala = salaSeleccionada;

    if (!!sistema.actual.tipoDeSala) {
      history.push('/'+ sistema.actual.tipoDeSala.toLowerCase());
    } else {
      history.push('/sala');
    }

  }

  const infoDeSala = (item) => {
    if (!item) {
      return '';
    }

    let $infoExtra = '';
    $infoExtra += item.costoPorHora ? item.costoPorHora + ' cr/h' : '';
    $infoExtra += item.capacidad ? ' ' + item.capacidad + ' per' : '';
    return $infoExtra;
  }

  return (
    <div className={"info-de-salas-page"}>
      <TituloConBack titulo="Información de Salas" />
      <div className="wrapper--scrollable-content__layout">
        <div className="wrapper--scrollable-content__column">
          <ol className="info-de-salas">
            {salas.map((sala) =>
              <li key={sala.id} className="info-de-sala">
                <SalaCard sala={sala} onClick={() => seleccionarSala(sala)} />
              </li>
            )}
          </ol>
        </div>
      </div>

    </div>
  );
}
