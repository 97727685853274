import React from "react";

export default ({titulo, className, leyendaPre, leyendaBold, leyendaPost, label, onClick, disabled = false, ...props}) =>
<div className={"fixed-CTA" + (className ? ' ' + className : '')}>
  <div>
    <h1 className="fixed-CTA__titulo">{titulo}</h1>
    <span className="fixed-CTA__leyenda pre">{leyendaPre} </span>
    <span className="fixed-CTA__leyenda bold"> {leyendaBold} </span>
    <span className="fixed-CTA__leyenda post"> {leyendaPost}</span>
    {props.children}
  </div>
  <button className="fixed-CTA__control" onClick={onClick} disabled={disabled}><span className="fixed-CTA__label">{label}</span></button>
</div>