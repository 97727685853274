import React from 'react';
import ListaDeComunidad from '../../components/ListaDeComunidad';
import Cover from '../Home/components/Cover';

export default ({...props}) => {

  return (
    <div className="comunidad-page">
      <Cover slider={false} images={["/img/cover-comunidad-"+ (props.isPortrait ? 'mobile' : 'desktop') + '.png']} />
      <ListaDeComunidad {...props} />
    </div>
  );
}