import React from "react";

export default ({texto, isSelected, onClick, ...props}) => {
  return (
    <button onClick={onClick} className={"selector-item" + (isSelected ? ' is-checked' : '')}>
      <div className="selector-item__details">
        <span className="selector-item__titulo">{texto}</span>
        <div className="selector-item__icon">
          <i className="icon--check"/>
        </div>
      </div>
      {props.children}
    </button>
  )
}