import { login } from '../lib/auth';
import Usuario from './Usuario';
export default class Sistema {
  constructor() {
    this.espacio = null;
    this.espacios = [];
    this.actual = localStorage.actual ? JSON.parse(localStorage.actual) : {};
    this.usuario = null;
    this.oficinas = null;
    this.noImageURL = localStorage.sistema?.noImageURL ? localStorage.sistema.noImageURL : null;
  }

  setEspacioID(espacioID) {
    if (this.espacios.length > 0) {
      this.espacios.forEach((espacio) => {
        if (espacio.id === espacioID) {
          this.espacio = espacio;
        }
      })
    }
  }

  saveActual() {
    localStorage.actual = JSON.stringify(this.actual);
  }

  loadActual() {
    this.actual = JSON.parse(localStorage.actual);
  }

  refreshUser(setUsuario) {
    login((usuarioData) => {
      this.usuario = new Usuario(usuarioData);
      this.space_id = usuarioData.space_id;
      this.oficinas = usuarioData.oficinas;
      if (setUsuario) {
        setUsuario(this.usuario);
      }
    });
  }

}

export const sistema = new Sistema();