import React from "react";
import Fecha from "../EventoDeCalendario/components/Fecha";

export default ({titulo, descripcion, checked, fecha, reservas, miReserva, onClick, status, ...props}) => {

  return (
    <button className={"boton-calendario " + status + (checked ? ' is-checked' : '')} onClick={onClick} disabled={status !== 'disponible'}>
      <Fecha fecha={fecha}/>
      <ul className="boton-calendario__reservas">
        {reservas && (reservas.length > 0) && reservas.map((item, index) =>
          <li key={index} className="attendee">
            {item.usuario.imagen
              ? <img className="attendee-avatar" src={item.usuario.imagen} alt=""/>
              : <div className="attendee-avatar"><span className="attendee-avatar__text">{item.usuario.nombres[0]+item.usuario.apellidos[0]}</span></div>
            }
          </li>
        )}
        {miReserva &&
          <li key="miReserva" className="attendee">
            {miReserva.usuario.imagen
              ? <img className="attendee-avatar" src={miReserva.usuario.imagen} alt=""/>
              : <div className="attendee-avatar"><span className="attendee-avatar__text">{miReserva.usuario.nombres[0]+miReserva.usuario.apellidos[0]}</span></div>
            }
          </li>
        }
      </ul>
      <div className="boton-calendario__textos">
        <h1 className="boton-calendario__textos-titulo">{titulo}</h1>
        <p className="boton-calendario__textos-descripcion">{descripcion}</p>
      </div>
      <div className={"boton-calendario__control"}><i className="icon--check"></i></div>
    </button>
  );
}