import React from "react";

export default ({nombre, descripcion, conPuestos, creditos, ...props}) => {

  return (
    <div className={"membresia"} >
      {!conPuestos
        ? <div className="membresia-textos">
          <h1 className="membresia-textos__titulo">{nombre}</h1>
          <h2 className="membresia-textos__creditos">{creditos + ' créditos'}</h2>
          <p className="membresia-textos__descripcion">{descripcion}</p>
        </div>
        : <h1 className="membresia-textos__titulo">{props.puestos + ' - ' + nombre}</h1>
      }
    </div>
  );
}