import React, { useEffect, useState } from "react";
import 'moment/locale/es';
import moment from "moment";
import Moment from "react-moment";

export default ({fecha, type = 'week', esFechaDisponible, onChange, ...props}) => {
  moment.updateLocale('es', {
    week: {
      dow: 0
    }
  })

  const [fechaMoment, setFechaMoment] = useState(moment(fecha).locale('es'));
  const [dias, setDias] = useState([]);


  const changeCalendar = (value, units) => {
    const nuevoMoment = moment(fecha).add(value,units);
    const nuevaFecha = nuevoMoment.toDate();
    if (onChange) {
      onChange(nuevaFecha);
    }
  }

  //let dias = [];//new Date(fecha.getFullYear(), fecha.getMonth() + 1 , 0).getDate();

  useEffect(() => {
    setFechaMoment(moment(fecha).locale('es'));
  }, [fecha]);

  useEffect(() => {
    let aMoment = fechaMoment.clone();
    let cantidadDeDias = ((type === 'week') ? 7 : aMoment.endOf('month').date());
    let diasArray = [];

    aMoment.startOf(type);

    for (let i = 1; i <= cantidadDeDias; i++) {
      diasArray.push({
        number: aMoment.date(),
        date: aMoment.toDate(),
        isSelected: aMoment.date() === fechaMoment.date(),
        disponible: (esFechaDisponible ? esFechaDisponible(aMoment.toDate()) : true),
        dayClass: (i === 1) ? ' day--' + aMoment.day() : '',
      });
      aMoment.add(1, 'days');
    }
    setDias(diasArray);
  }, [fechaMoment, type, esFechaDisponible]);

  return (
    <div className="calendario">
      <div className="calendario__row calendario-titulo">
        <button className="calendario__control" onClick={() => {changeCalendar(-1, type+'s')}}><img src="/img/chevron.svg" alt=""/></button>
        <span className="calendario__fecha"><Moment format="D [de] MMMM [de] YYYY" locale="es">{fecha}</Moment></span>
        <button className="calendario__control" onClick={() => {changeCalendar(+1, type+'s')}}><img src="/img/chevron.svg" alt="" style={{transform: 'rotate(180deg)'}} /></button>
      </div>
      <div className="calendario__row">

        <ol className="calendario__nombres">
          {fechaMoment.get().localeData().weekdaysMin().map((dia, index) => (
            <li className="day" key={index}>{dia}</li>
          ))}
        </ol>

      </div>
      <div className={"calendario__" + (props.isPortrait ? "row" : "month")}>
        <ol className="calendario__dias">
          {dias.map((dia,index) => (
            <li className={"calendario__dia date" + (dia.isSelected ? ' is-selected' : '') + dia.dayClass + (dia.disponible ? '' : ' is-no-disponible')} key={dia.number} onClick={() => {dia.disponible && onChange(dia.date)}}><span>{dia.number}</span></li>
          ))}
        </ol>
      </div>

    </div>
  );
};
