import React from 'react';

import logo from '../../mimqco--logo.svg';
import './styles.css';

export const SplashScreen = ({version}) => {
  return (
    <div className="Splash">
      <img src={logo} className="Splash-logo" alt="logo" />
      <span>{'v'+version}</span>
    </div>
  )

}

export default SplashScreen;