import React, { useEffect, useState } from 'react';
import MiHistorial from '../../components/MiHistorial';
import TituloConBack from '../../components/TituloConBack';
import { getUIData, sendRequest } from '../../lib/serverAPI';
import { sistema } from '../../model/Sistema';

export default ({usuario, ...props}) => {
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [filtros, setFiltros] = useState(sistema?.actual?.filtros ?? {desde: fechaDesde, hasta: fechaHasta});
  const [eventos, setEventos] = useState([]);

  const [viendoIndividuo, setViendoIndividuo] = useState(sistema?.actual?.viendoIndividuo ?? true);

  const [adminAccess, setAdminAccess] = useState(false);
  const [empresa, setEmpresa] = useState(null);

  const [pageSize, setPageSize] = useState(200);
  const [page, setPage] = useState(sistema?.actual?.page ?? 1);

  useEffect(() => {
    sistema.actual.filtros = filtros;
    sistema.actual.page = page;
    actualizarEventos();
    // getUIData('getMiHistorial', { filtros, limit: pageSize, offset: pageSize * (page - 1) }, data => {
    //   setEventos(data.map(evento => {
    //     if (!viendoIndividuo && !!empresa) {
    //       evento.usuario = empresa.empleados.find(item => item.id === evento.usuario_id);
    //     }
    //     return evento;
    //   }));
    // });
  }, [filtros, page]);

  useEffect(() => {
    const nuevosFiltros = Object.assign({}, filtros);
    if ((sistema?.actual?.viendoIndividuo !== viendoIndividuo)) {
      setPage(1);
    }
    sistema.actual.viendoIndividuo = viendoIndividuo;
    if (!viendoIndividuo) {
      nuevosFiltros.empresaID = usuario.empresa.id;
      setFiltros(nuevosFiltros);
    } else {
      delete (nuevosFiltros.empresaID);
      setFiltros(nuevosFiltros);
    }
  }, [viendoIndividuo])

  useEffect(() => {
    sistema.actual.accion = null;
    if (!!usuario.empresa) {
      sendRequest('post', 'empresa', 'getEmpresa', { empresaID: usuario.empresa.id }, response => {
        if (response.success) {
          response.data.empresa.empleados = Object.values(response.data.empresa.empleados);
          response.data.empresa.staff = Object.values(response.data.empresa.staff);
          setEmpresa(response.data.empresa);
          setAdminAccess(!!response.data.empresa.staff.find(item => item.id === usuario.id));
        }
      });
    }
  }, []);

  useEffect(() => {
    actualizarEventos();
  }, [empresa]);

  const actualizarEventos = () => {
    getUIData('getMiHistorial', { filtros, limit: pageSize, offset: pageSize * (page - 1) }, data => {
      setEventos(data.map(evento => {
        if (!viendoIndividuo && !!empresa) {
          evento.usuario = empresa.empleados.find(item => item.id === evento.usuario_id);
        }
        return evento;
      }));
    });
    // setEventos(listado.map(item => {
    //   const miItem = listado.find(calItem => calItem.id === item.id);
    //   return item;
    // }));
  }

  return (
    <div className="historial-page">
      <TituloConBack titulo="Historial" />
      {!!usuario.empresa && adminAccess && <div className="tipo-de-perfil--selector">
        <div className={"selector-link" + (viendoIndividuo ? ' is--selected' : '')} onClick={() => {setViendoIndividuo(true)}}>Individuo</div>
        <div className={"selector-link" + (!viendoIndividuo ? ' is--selected' : '')} onClick={() => {setViendoIndividuo(false)}}>Empresa</div>
      </div>}
      <MiHistorial eventos={eventos} {...props} completo={eventos.length === pageSize} page={page} nextPage={() => setPage(page + 1)} previowsPage={() => setPage(page - 1)} />
    </div>
  );
}