import React from "react";
import { useHistory } from "react-router";
import { sistema } from "../../model/Sistema";

export default ({ id, nombres, apellidos, imagen, aptitudes, contactable, ...props }) => {

  const history = useHistory();

  const onOpenMaquinero = () => {
    sistema.actual.id = id;
    history.push("/maquinero/"+id);
  }

  return (
    <div className="maquinero-card">
      <div className="maquinero-card__avatar">
        <i className="maquinero-card__avatar__notification-icon icon--circle hidden"></i>
        {imagen
          ? <div className="maquinero-card__avatar__image" src={imagen} style={{backgroundImage: `url(${imagen})`}} alt="" />
          : <div className="maquinero-card__avatar__image">
              <span className="maquinero-card__avatar__text">
                {nombres[0]+apellidos[0]}
              </span>
            </div>
        }
      </div>
      <h1 className="maquinero-card__nombre">
        {nombres + (apellidos ? " " + apellidos : "")}
      </h1>
      <p className="maquinero-card__aptitudes">
        {aptitudes && (aptitudes.length > 0) && aptitudes.map((aptitud, index) => {
          return (// index < 2
            // ?
            aptitud.nombre + (index + 1 < aptitudes.length ? ", " : "")
            //: ((index === 2) && (props.aptitudes.length > 2) ? "..." : "");
        )})}
      </p>
      {contactable && <button onClick={onOpenMaquinero} className="maquinero-card__control button--stroke">
        Contactar
      </button>}
    </div>
  );
};
