import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import StatusBarMobile from '../../components/statusBarMobile';
import StatusBarDesktop from '../../components/statusBarDesktop';
import NavBarMobile from '../../components/navigationMobile';
import NavBarDesktop from '../../components/navigationDesktop';
import Container from '../../components/mainContainer';

import Home from '../Home';
import Reservas from '../Reservas';
import Experiencias from '..//Experiencias';
import Menu from '../Menu';
import Comunidad from '../Comunidad';
import ReservaDePosicion from '../ReservaDePosicion';
import DetalleDeExperiencia from '../DetalleDeExperiencia';
import SeleccionDeEscritorio from '../SeleccionDeEscritorioMulti';
import Confirmar from '../Confirmar';
import Exito from '../Exito';
import SeleccionDeOficina from '../SeleccionDeOficina';
import MiOficina from '../MiOficina';
import ReservaDeSala from '../ReservaDeSala';
import OnBoarding from '../OnBoarding';
import CancelarEvento from '../CancelarEvento';
import ExitoDeExperiencia from '../ExitoDeExperiencia';
import ExitoOnboarding from '../ExitoOnboarding';
import Perfil from '../Perfil';
import Ayuda from '../Ayuda';
import SolicitudDeCreditos from '../SolicitudDeCreditos';
import Historial from '../Historial';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import DetalleDeMaquinero from '../DetalleDeMaquinero';
import Beneficios from '../Beneficios';
import DetalleDeBeneficio from '../DetalleDeBeneficio';
import { sendRequest } from '../../lib/serverAPI';
import QRPage from '../QRPage';
import Transacciones from '../Transacciones';
import APIManager from '../../managers/APIManager';
import InfoDeSalas from '../InfoDeSalas';
import InfoDeSectores from '../InfoDeSectores';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ThemeProvider, createTheme } from '@mui/material';
import FirmarContrato from '../FirmarContrato';
import ReservaDeCallRoom from '../ReservaDeCallRoom';
import { redirectToLoginApp } from '../../lib/auth';
import EstadoDeCuenta from '../EstadoDeCuenta';


function Main({...props}) {
  const history = useHistory();

  const [menuVisible, setMenuVisible] = useState(false)
  const [seccion, setSeccion] = useState('/home')

  const [notificaciones, setNotificaciones] = useState([]);
  const [notificacion, setNotificacion] = useState(null);

  const [firmaPendiente, setFirmaPendiente] = useState(false);

  const toggleMenu = () => { setMenuVisible(!menuVisible) }

  // const mdTheme = createTheme();
  const mdTheme = createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#ffac8f',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
    },
  });

  const redireccionarUsuario = () => {
    if (props.usuario && props.usuario.status) {
      switch (props.usuario.status) {
        case 'new':
          if (history.location.pathname !== '/onboarding') {
            history.push("/onboarding");
          }
          break;
        case 'pending-approval':
        case 'approved':
        default:
          // TODO: Armar 404
           //if (history.location.pathname === '/') {
            history.push("/home");
           //}
          break;
      }
    } else {
      history.push("/login");
    }

  }

  const [showModal, setShowModal] = useState(true);

  const handleClose = () => setShowModal(false);

  useEffect(() => {
    if (!notificacion) {
      APIManager.getList('notificaciones', { active: true }, (response) => {
        setNotificaciones(response.data.listado);
      });
    }
    if (!!props.usuario && (props.usuario.status === "pending-signature")) {
      setFirmaPendiente(!!props.usuario.contratoAdminAccess);
    }
  }, []);

  useEffect(() => {
    if (notificaciones.length > 0) {
      setNotificacion(notificaciones.find(item => (item.tipo === 'MAIN') && item.active));
    }
  }, [notificaciones])

  useEffect(() => {
    if (!!notificacion) {
      setShowModal(true);
    }
  }, [notificacion])

  return (
    <ThemeProvider theme={mdTheme}>
      <div className={"main-view" + (props.isPortrait ? ' mobile' : ' desktop')}>
      {!!props.usuario && !props.usuario.activo && <Dialog onClose={() => redirectToLoginApp()} className="main-modal" open={true}>
          <DialogTitle><h1 className="main-modal-title">Usuario no habilitado</h1></DialogTitle>
          <DialogContent>
            <span>{'Consulte con el STAFF por la cuenta: '}</span><br/>
            <span>{props.usuario.descripcion}</span>
          </DialogContent>
          <DialogActions sx={{justifyContent: 'center'}}>
            <button className="main-modal-button" onClick={() => redirectToLoginApp()}>Aceptar</button>
          </DialogActions>
        </Dialog>}
        {!!firmaPendiente && !!props.usuario.contratoAdminAccess && <Dialog onClose={() => setFirmaPendiente(false)} className="main-modal" open={true}>
          <DialogTitle><h1 className="main-modal-title">Firma del contrato pendiente</h1></DialogTitle>
          <DialogContent>
            <span>Para utilizar MiMQco, debes dar de alta el contrato.</span>
          </DialogContent>
          <DialogActions sx={{justifyContent: 'center'}}>
            <button className="main-modal-button" onClick={() => {
              setFirmaPendiente(false);
              history.push('/firmar');
            }}>Firmá tu contrato</button>
          </DialogActions>
        </Dialog>}
        {notificacion && <Modal className="main-modal" show={showModal} onHide={handleClose} animation={false} aria-labelledby="contained-modal-title-vcenter"
          centered={true}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <img className="main-modal-title-icon" src="/img/icon--attention.svg" alt="" />
              <h1 className="main-modal-title" >{notificacion.titulo}</h1>
              <h4 className="main-modal-subtitle">{notificacion.subtitulo}</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <pre className="main-modal-instructions">{notificacion.texto}</pre>
            {/* {notificacion.texto.split('\n').map((linea, index) => <h4 key={index} className="main-modal-instructions"> {linea}</h4>)} */}
            {/* <h4 className="main-modal-instructions">En AMBA el horario es de 9 h a 19 h.</h4>
            <h4 className="main-modal-instructions">En Rosario el horario es de 8 h a 19 h.</h4>
            <h4 className="main-modal-instructions">En Córdoba continuamos operando en el horario habitual.</h4> */}
          </Modal.Body>
          <Modal.Footer className="main-modal-footer">
            <Button className="main-modal-button" onClick={handleClose}>Entendido !</Button>
          </Modal.Footer>
        </Modal>}
        {props.isPortrait ? <StatusBarMobile {...props} showMenu={toggleMenu} /> : <StatusBarDesktop {...props} /> }
        <Container className={props.isPortrait ? 'mobile layout--status-visible layout--navigation-visible' : 'desktop'} >
          <Switch>
            <Route path="/home" render={(myProps) => <Home {...props} {...myProps} />} />
            <Route path="/reservas" render={(myProps) => <Reservas setSeccion={setSeccion} {...props} {...myProps} />} />
            <Route path="/experiencias" render={(myProps) => <Experiencias {...props} {...myProps} />} />
            <Route path="/experiencia/:id" render={(myProps) => <DetalleDeExperiencia setSeccion={setSeccion} {...props} {...myProps} />} />
            <Route path="/comunidad" render={(myProps) => <Comunidad {...props} {...myProps} />} />
            <Route path="/beneficios" render={(myProps) => <Beneficios {...props} {...myProps} />} />
            <Route path="/beneficio/:id" render={(myProps) => <DetalleDeBeneficio setSeccion={setSeccion} {...props} {...myProps} />} />
            <Route path="/maquinero/:id" render={(myProps) => <DetalleDeMaquinero setSeccion={setSeccion} {...props} {...myProps} />} />
            <Route path="/posicion" render={(myProps) => <ReservaDePosicion setSeccion={setSeccion} {...props} {...myProps} />} />
            <Route path="/escritorio" render={(myProps) => <SeleccionDeEscritorio setSeccion={setSeccion} {...props} {...myProps} />} />
            <Route path="/mioficina" render={(myProps) => <MiOficina {...props} {...myProps} />} />
            <Route path="/oficina" render={(myProps) => <SeleccionDeOficina {...props} {...myProps} />} />
            <Route path="/sala" render={(myProps) => <ReservaDeSala setSeccion={setSeccion} {...props} {...myProps} />} />
            <Route path="/callroom" render={(myProps) => <ReservaDeCallRoom setSeccion={setSeccion} {...props} {...myProps} />} />
            <Route path="/confirmar" render={(myProps) => <Confirmar {...props} {...myProps} />} />
            <Route path="/cancelar" render={(myProps) => <CancelarEvento {...props} {...myProps} />} />
            <Route path="/exito" render={(myProps) => <Exito {...props} {...myProps} />} />
            <Route path="/exitoexperiencia" render={(myProps) => <ExitoDeExperiencia {...props} {...myProps} />} />
            <Route path="/exitoonboarding" render={(myProps) => <ExitoOnboarding {...props} {...myProps} />} />
            <Route path="/perfil" render={(myProps) => <Perfil {...props} {...myProps} />} />
            <Route path="/onboarding" render={(myProps) => <OnBoarding {...props} {...myProps} />} />
            <Route path="/creditos" render={(myProps) => <SolicitudDeCreditos {...props} {...myProps} />} />
            <Route path="/historial" render={(myProps) => <Historial {...props} {...myProps} />} />
            <Route path="/transacciones" render={(myProps) => <Transacciones {...props} {...myProps} />} />
            <Route path="/cuenta" render={(myProps) => <EstadoDeCuenta {...props} {...myProps} />} />
            <Route path="/ayuda" render={(myProps) => <Ayuda {...props} {...myProps} />} />
            <Route path="/infodesalas" render={(myProps) => <InfoDeSalas {...props} {...myProps} />} />
            <Route path="/infodesectores" render={(myProps) => <InfoDeSectores {...props} {...myProps} />} />
            <Route path="/firmar" render={(myProps) => <FirmarContrato {...props} {...myProps} />} />
            <Route path="/qr" render={(myProps) => <QRPage {...props} {...myProps} />} />
            <Route path="/" exact={false} render={() => {redireccionarUsuario()}} />
          </Switch>
        </Container>
        <Menu visible={menuVisible} toggleMenu={toggleMenu} {...props} />
        {!!props.usuario && !!props.usuario.status &&
          (props.usuario.status !== 'new') && // Para evitar mostrar el menu si el usuario no completó su perfil
          (props.isPortrait
          ? <NavBarMobile seccion={seccion} setSeccion={setSeccion} {...props} hideMenu={() => { setMenuVisible(false); return true }} />
          : <NavBarDesktop seccion={seccion} setSeccion={setSeccion} {...props} showMenu={toggleMenu} />)
        }
      </div>
    </ThemeProvider>

  );
}

export default Main;