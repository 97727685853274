import React from 'react';

export default ({titulo, turno, ubicacion, costo, usuario}) => (
  <div className="event-item__details">
    <span className="event-item__type">
      {titulo}
    </span>
    <div className="wrapper--second-row">
      <div className="event-item__time">
        {/*<i className="icon--clock"></i>*/}
        <img className="icon--clock" src="/img/icon--time-grey.svg" alt=""></img>
        <span className="event-item__time--text">{turno}</span>
      </div>
      <div className="event-item__place">
        {/*<i className="icon--map-pin"></i>*/}
        <img className="icon--map-pin" src="/img/icon--location-grey.svg" alt=""></img>
        <span className="event-item__place--text">{ubicacion}</span>
      </div>
    </div>
    <div className="wrapper--third-row">
      <div className="event-item__extra">
        {/* <img className="icon--money" src="/img/icon--money.svg" alt=""></img> */}
        <span className="event-item__credits--text">{(!!costo ? -costo : 0) + ' cr'}</span>
        <span className="event-item__user--text">{!!usuario ? usuario.descripcion : ''}</span>
      </div>
      {/* <div className="event-item__place">
        <img className="icon--map-pin" src="/img/icon--location-grey.svg" alt=""></img>
        <span className="event-item__place--text">{ubicacion}</span>
      </div> */}
    </div>
  </div>
)