import React from 'react';
import Experiencias from '../../components/Experiencias';
import MiSemana from '../../components/MiSemana';
import Cover from '../Home/components/Cover';

export default ({eventos, ...props}) => {


  return (
    <div className="experiences-page">
      <Cover titulo="" slider={false} images={["/img/cover-experiencias-"+ (props.isPortrait ? 'mobile' : 'desktop') + '.png']} />
      <MiSemana titulo="Mi Semana" {...props} filtros={{tipo: "soloExperiencias"}} />
      <Experiencias {...props} full={true} />
    </div>
  );
}