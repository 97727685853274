import React, { useEffect, useState } from 'react';

import { getUIData } from '../../lib/serverAPI';
import ListaDeMaquineros from '../ListaDeMaquineros';
import APIManager from '../../managers/APIManager';
import Selector from '../Selector';

export default ({eventos, usuario, ...props}) => {
  // const categorias = [{id:1, nombre:'Mi sede'},{id:2, nombre: 'Otras sedes'}];
  const [items, setItems] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [categoriaActual, setCategoriaActual] = useState(categorias[0]);
  const [aptitudesGenerales, setAptitudesGenerales] = useState([]);
  const [aptitudes, setAptitudes] = useState([]);
  const [aptitud, setAptitud] = useState(null);
  const [searchString, setSearchString] = useState('');

  const cargarAptitudes = () => {
    APIManager.getList('skills', {}, (response) => {
      // response.data.listado.push({ id: null, name: 'TODAS' });
      setAptitudesGenerales(response.data.listado.map(item => {
        item.nombre = item.name;
        return item;
      }));
    })
  }

  const quitarAptitud = (item) => {
    setAptitudes(aptitudes.filter(aptitud => aptitud.id !== item.id));
  }

  const agregarAptitud = (item) => {
    setAptitud(null);
    // setAptitudes([...aptitudes, item]);
    setAptitudes([item]);
  }

  const quitarAptitudes = () => {
    setAptitud(null);
    setAptitudes([]);
  }


  useEffect(() => {
    getUIData('getMaquineros', {limit: 24, search: searchString, skillID: !!aptitud ? aptitud.id : null}, (maquineros) => {
      // Mientras no tengamos como contactar, los maquineros son todos "no contactables"
      // Alguien cuyo estado no es "approved" no puede contactar a nadie
      if (true || (usuario.status !== 'approved'))
      maquineros.forEach(element => {
        element.contactable = true;
      });
      setItems(maquineros)
    });
  }, [categoriaActual, searchString, usuario.status, aptitud]);

  useEffect(() => {
    cargarAptitudes();
  }, []);

  const searchChange = (event) => {
    setSearchString(event.target.value);
  }

  return (
    <section className="comunidad-showcase">
      <div className="comunidad-inputs">
        <div className="comunidad-buscar">
          <i className="icon--search"></i>
          <input placeholder="Buscar" onChange={searchChange}/>
        </div>
        <div className="aptitudes-combo">
          <Selector titulo="Aptitud" className="aptitudes--selector" selectedItem={aptitud} items={aptitudesGenerales.filter(item => !aptitudes.find(aptitud => aptitud.id === item.id))} icon="" onChange={setAptitud} folded={true} />
          {/* <button className="aptitud-button--nuevo" onClick={() => agregarAptitud(aptitud)}><i className="icon--plus"></i></button> */}
          {!!aptitud && <button className="aptitud-button" onClick={quitarAptitudes}><i className="icon--times"></i></button>}
        </div>
      </div>
      {/* <div className="aptitudes">
        {aptitudes && aptitudes.map((item, index) =>
          <div key={item.id} className="aptitud">
            <span>{item.name}</span>
            <button className="aptitud-button" onClick={() => quitarAptitud(item)}><i className="icon--times"></i></button>
          </div>)}
      </div> */}
      {/*
      <ul className="comunidad-categories-list">
        {categorias.map((item) => (
          <li key={item.id} className={"comunidad-type" + (item === categoriaActual ? ' is-active' : '')}>
            <button className="comunidad-type__selector" onClick={() => {setCategoriaActual(item)}}>{item.nombre}</button>
          </li>
        ))}
      </ul>
      */}
      <ListaDeMaquineros items={items} />
      {/*
      <div className="comunidad-showcase__controls">
        <button className="comunidad-showcase__more-comunidad">
          M&aacute;s
          <i className="icon--chevron-right"></i>
        </button>
      </div>
      */}
    </section>
  );
}