import React, { useEffect, useState } from 'react';
import Evento from '../EventoDeCalendario';

import { getUIData } from '../../lib/serverAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default ({ eventos, titulo, filtros, limite = 4, ...props }) => {
  const history = useHistory();
  const [items, setItems] = useState([]);

  useEffect(() => {
    getUIData('getMiSemana', {filtros}, setItems);
  }, [filtros]);

  return (
    (props.usuario.status === 'approved') &&
    <section className="my-week">
        <h1>{titulo}</h1>
        <ol className="event-list">
          {items && (items.length > 0) ? items.map((item, index) => (
            (index < limite) &&
            <li key={item.id} className={"event-item" + (item.tipo === 'Evento' ? ' event-type--purple' : '')}>
              <Evento evento={item} />
            </li>
          )) : <li key="0" className="event-item">
            <div className="event-item__details">
              <span className="event-item__type">No hay nada agendado</span>
            </div></li>
          }
        </ol>
        {(items.length > limite) &&
          <button className="control--info-de-salas" onClick={() => { history.push('/historial') }}>Ver más...</button>
        }
    </section>
  );
}