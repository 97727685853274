import React, { useEffect, useState } from 'react';
import ListaDeBeneficios from '../../components/ListaDeBeneficios';
import Cover from '../Home/components/Cover';
import { getUIData } from '../../lib/serverAPI';

export default ({...props}) => {
  const categorias = [{id:1, nombre:'Mi sede'},{id:2, nombre: 'Otras sedes'}];
  const [items, setItems] = useState([]);
  //const [categorias, setCategorias] = useState([{id:1, nombre:'Mi sede'},{id:2, nombre: 'Otras sedes'}]);
  const [categoriaActual, setCategoriaActual] = useState(categorias[0]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    getUIData('getBeneficios', {limit: 24, search: searchString}, (beneficios) => {
      // Mientras no tengamos como contactar, los maquineros son todos "no contactables"
      // Alguien cuyo estado no es "approved" no puede contactar a nadie
      setItems(beneficios);
      // console.log(beneficios);
    });
  }, [categoriaActual, searchString]);

  const searchChange = (event) => {
    setSearchString(event.target.value);
  }

  return (
    <div className="beneficios-page">
      <Cover slider={false} images={["/img/cover-beneficios-"+ (props.isPortrait ? 'mobile' : 'desktop') + '.png']} />
      <section className="beneficios-showcase">
      <div className="beneficios-buscar">
        <i className="icon--search"></i>
        <input placeholder="Buscar" onChange={searchChange}/>
      </div>
      <ListaDeBeneficios items={items} />
    </section>
    </div>
  );
}