import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TarjetaDeContrato = ({ contrato, usuario, adminAccess, onVerContrato, onFirmarContrato }) =>
{
  const history = useHistory();

  return (!!contrato &&
    <div className="contrato-card membresia-wrapper">
      <h1 className="membresia__titulo">{contrato.nombre}</h1>
      {!!contrato.template_id && !contrato.firmante &&
        <span className="membresia__titulo attention" >Firma del contrato pendiente</span>
      }
      <div className="contrato__info">
        {!!contrato.template_id && (!!adminAccess
          ? <>
            <button className="contrato__button button--icon-only" onClick={onVerContrato}>

              <i className="icon--file-pdf"></i>
              <span>{'Ver Contrato' + (!contrato.documentoURLFull ? ' Preliminar' : '')}</span>
            </button>
            {!contrato.firmante &&
              <button className="contrato__button attention button--icon-only" onClick={onFirmarContrato}>
                <i className="icon--file-signature"></i>
                <span>Firmar Contrato</span>
              </button>}
          </>
          : <span className="attention" >Contactar al administrador de tu empresa.</span>)
        }
      </div>
      <h1 className="membresia__titulo">{contrato.creditos + ' créditos renovables'}</h1>
      <h1 className="membresia__titulo">{'Créditos disponibles: ' + usuario.creditos.balance}</h1>
      {/*
    <h1 className="membresia__titulo">{usuario.contrato ? 'Membresías' : 'Sin Membresía'}</h1>
    <hr />
    {usuario.contrato &&
      Object.values(usuario.contrato.puestos).map(item => item.puestos ? <Membresia key={item.id} {...item} conPuestos={true} /> : null)
    }
*/}
      {!!adminAccess && (contrato?.cliente?.balance !== null) && <>
        <h1 className="membresia__titulo mt-4">Estado de Cuenta</h1>
        <div className="contrato__info">
          <div className="input-label-combo balance">
            <label className='is--placeholder'>Balance</label>
            <span className={contrato.cliente.balance > 0 ? 'balance__deudor' : 'balance__acreedor'}>{parseFloat(contrato.cliente.balance).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</span>
            {/* <button className="buttonbutton--icon-only contrato__button" title="Ver detalle" onClick={() => history.push('/cuenta')}>
              <i className="icon--list" />
            </button> */}
          </div>
          <button className="contrato__button button--icon-only" onClick={() => history.push('/cuenta')}>
            <i className="icon--list"></i><span>Ver detalle</span>
          </button>
        </div>
        </>
        }
    </div>)
}

export default TarjetaDeContrato;