import React from 'react';

export default ({usuario, toggleMenu, ...props}) => {

  return (
    <div className="menu-cover">
      <h1 className="menu-cover__title">{usuario.nombres + ' ' + usuario.apellidos}</h1>
      <span className="menu-cover__text">{ usuario.membresia ? "Membresía " + usuario.membresia.nombre : "Sin Membresía"}</span>
      <button className="menu-cover__back" onClick={toggleMenu}><img src="/img/chevron.svg" alt="" /></button>
    </div>
  );
}