import React from "react";
import FAQItem from "../../components/FAQItem";
import TituloConBack from "../../components/TituloConBack";

export default () => <div className="ayuda-page">
  <TituloConBack titulo="Ayuda" />
  <div>
    <ul className="ayuda-faq-list">
      <FAQItem titulo="¿Hay staff de La Maquinita trabajando dentro de los espacios?" texto="¡Cada sede tiene su propio equipo que está para ayudarte en todo lo que necesites! Los encontrás en el Front Desk o en nuestros espacios comunes. " folded={true} />
      <FAQItem titulo="¿Se pueden traer invitados/as?" texto="¡Sí! En ese caso, tenés que reservar una sala. Por una cuestión de convivencia, no se pueden tener reuniones ni ingresar gente externa a los puestos de trabajo ya que eso podría molestar al resto." folded={true} />
      <FAQItem titulo="¿Se puede hablar por teléfono en los puestos?" texto="Queremos mantener un ambiente de trabajo tranquilo, por eso tenemos pequeñas salas para hacer llamados ¡Les decimos Call Rooms y no necesitan reserva! Además, siempre podés utilizar los espacios de uso común para hablar por teléfono." folded={true} />
      <FAQItem titulo="¿Dónde dejo mi computadora y elementos personales?" texto="Con la membresía full y own office podés dejar tu computadora en el escritorio todo el tiempo que quieras. Tus cosas personales pueden ir en un locker o mismo en tu puesto. Si tenés una membresía flex, la idea es que te lleves todo cuando te vayas o alquiles un locker para guardarlo ahí." folded={true} />
      <FAQItem titulo="¿Qué pasa si mi empresa necesita línea telefónica?" texto="Se puede tener línea telefónica dentro de las oficinas privadas. ¡Pedile ayuda a el o la manager de tu sede para coordinar la instalación! " folded={true} />
      <FAQItem titulo="¿Puedo agregarle cosas a mi oficina privada?" texto="Todas vienen equipadas con escritorios amplios, sillas ergonómicas super cómodas y modernas  y A/C propio. Si hay alguna otra cosa que quieras llevar, probablemente no haya problema, pero te pedimos que primero lo consultes con el o la manager del espacio." folded={true} />
      <FAQItem titulo="¿Hay un límite de horas de uso del espacio?" texto="¡Si! El límite de días y horarios depende de tu membresía y del tiempo que reserves tu posición." folded={true} />
      <FAQItem titulo="¿Puedo ir en bici?" texto="¡Podés! Todas las sedes de La Maquinita tienen espacio para guardar las bicicletas." folded={true} />
      <FAQItem titulo="¿Cómo reservo una sala si soy maquinero/a?" texto="Primero tenés que entrar a www.lamaquinita.co/reservas/ con tu usuario y reservar la sala que quieras usar, en la sede que mejor te quede. La cantidad de créditos varía según el plan que tengas y la misma página actualiza tu estado descontando los créditos que consuma la reserva de tu sala. Los créditos se renuevan todos los meses y no son acumulativos." folded={true} />
      <FAQItem titulo="¿Qué capacidad tienen las salas?" texto="Los tamaños de las salas varían según la sede. Hay salas de reuniones y capacitaciones para 3, 4, 6, 10, 12 y 20 personas." folded={true} />
      <FAQItem titulo="¿Puedo usar las salas de todas las sedes de la maquinita?" texto="¡Obvio!" folded={true} />
      <FAQItem titulo="¿Y los puestos de trabajo?" texto="Al ser miembro de La Maquinita siempre serás bienvenido/a en nuestros distintos espacios, pero cada maquinero/a tiene que elegir una sede de referencia. Igualmente, si necesitás trabajar desde otra Maquinita, podés. Tenés que hacerlo reservando tu posición o una sala de reuniones. " folded={true} />
      <FAQItem titulo="¿Qué pasa si se me acaban los créditos para reservar?" texto="Siempre está la opción de comprar más créditos. Tenemos paquetes de 10, 50 y 100 créditos o podés comprarlos individualmente con un mínimo de 10 ¡Por ser maquinero/a los podes comprar con descuento y estos créditos no vencen!" folded={true} />
      <FAQItem titulo="¿Para qué sirve MiMQco?" texto="MiMQco es principalmente una plataforma para mantenernos conectados/as y gestionar tu membresía. En la sección de Comunidad podrás buscar y conectarte con cualquier otro miembro de este network del que sos parte. Además en las secciones restantes podrás realizar la reserva de tu posición, de las salas de reuniones y de las experiencias a las que quieras asistir. ¡Todo en un mismo lugar!
      " folded={true} />
      <FAQItem titulo="¿Qué lugares cercanos a La Maquinita tengo para comer, comprar insumos útiles, etc?" texto="¡En cada Maqui tenemos un mapa que tiene marcados todos los lugares cercanos de posible interés para los maquineros! Los mismos son colaborativos, por lo cual podés agregarle cualquier lugar que creas que está faltando. A continuación, te dejamos los links a los mapas de cada espacio:" folded={true} >
        <ul className="lista-de-sedes">
          <li>
              <span>Villa Crespo:</span>
              <a href='https://goo.gl/pYMZ5H'> https://goo.gl/pYMZ5H</a>
          </li>
          <li>
              <span>Belgrano:</span>
              <a href='https://goo.gl/dPuc7T'> https://goo.gl/dPuc7T</a>
          </li>
          <li>
              <span>Palermo Hollywood:</span>
              <a href='https://goo.gl/XtQw8Q'> https://goo.gl/XtQw8Q</a>
          </li>
          <li>
              <span>Palermo Soho:</span>
              <a href='https://goo.gl/cx1Cma'> https://goo.gl/cx1Cma</a>
          </li>
          <li>
              <span>Vicente López:</span>
              <a href='https://goo.gl/4nsooB'> https://goo.gl/4nsooB</a>
          </li>
        </ul>
      </FAQItem>
    </ul>
  </div>
</div>