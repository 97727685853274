import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sistema } from '../../model/Sistema';
import { getUIData, sendRequest } from '../../lib/serverAPI';
import TituloConBack from '../../components/TituloConBack';
import moment from 'moment';

export default ({ usuario, ...props }) => {

  const history = useHistory();
  let { id } = useParams();
  const [maquinero, setMaquinero] = useState(null);

  const [mensaje, setMensaje] = useState('');
  const [notification, setNotification] = useState('');

  if (!sistema.actual.id && id) {
    sistema.actual.id = id;
  }
  if (!sistema.actual.id) {
    history.push('/comunidad');
  }

  useEffect(() => {
    getUIData('getMaquinero', {id: sistema.actual.id}, setMaquinero);
  }, []);

  props.setSeccion('/comunidad');


  const handleMensaje = (event) => {
    setMensaje(event.target.value);
  }

  const enviarMensaje = () => {
    sendRequest('post', null, 'community/message', { recipient_id: maquinero.id, message: mensaje }, (response) => {
      if (response.success) {
        setNotification('Mensaje enviado!');
        setMensaje('');
      }
    });

  }

  const closeNotification = () => {
    setNotification(null);
  }

  return (maquinero &&
    <div className="detalle-de-maquinero">
      <TituloConBack titulo="Comunidad" />
      <div className="detalle-de-maquinero__main">
        <div className="maquinero-card__avatar">
          <i className="maquinero-card__avatar__notification-icon icon--circle hidden"></i>
          {maquinero.imagen
            ? <div className="maquinero-card__avatar__image" src={maquinero.imagen} style={{backgroundImage: `url(${maquinero.imagen})`}} alt="" />
            : <div className="maquinero-card__avatar__image">
                <span className="maquinero-card__avatar__text">
                  {maquinero.nombres[0]+maquinero.apellidos[0]}
                </span>
              </div>
          }
        </div>
        <div className="datos">
          <h1 className="detalle-de-maquinero__nombre">
            {maquinero.nombres + (maquinero.apellidos ? " " + maquinero.apellidos : "")}
          </h1>
          <p className="detalle-de-maquinero__aptitudes">
            {maquinero.aptitudes && (maquinero.aptitudes.length > 0) && maquinero.aptitudes.map((aptitud, index) => {
              return (
                aptitud.nombre + (index + 1 < maquinero.aptitudes.length ? ", " : "")
            )})}
          </p>
        </div>
      </div>
      <div className="detalle-de-maquinero__info">
        <span className="detalle-de-maquinero__fecha">Miembro desde: { moment(maquinero.fechaDeIngreso).locale('es').format('D [de] MMM [de] YYYY') }</span>
        <p className="detalle-de-maquinero__aspects">
          {maquinero.professional_aspects && maquinero.professional_aspects}
        </p>
        <p className="detalle-de-maquinero__highlights">
          {maquinero.professional_aspects && maquinero.professional_highlights}
        </p>
        <p className="detalle-de-maquinero__keywords">
          {maquinero.professional_aspects && maquinero.professional_keywords}
        </p>
      </div>
      <div className="detalle-de-maquinero__contactar">
      <label className="detalle-de-maquinero__contactar-label">Contactar</label>
      <div className="detalle-de-maquinero__contactar-form">
        {notification && <div className="detalle-de-maquinero__notification" onClick={closeNotification}>
          <span className="detalle-de-maquinero__notification-text">{notification}</span>
          <button className="detalle-de-maquinero__notification-button">x</button>
        </div>}
        <input className="detalle-de-maquinero__contactar-input" value={mensaje} onChange={handleMensaje} placeholder="Escribe un mensaje..."></input>
        <button className="detalle-de-maquinero__contactar-button" onClick={enviarMensaje}>Enviar</button>
      </div>
      </div>
    </div>
  )
}