import React, { useEffect, useState } from 'react';
import TituloConBack from '../../components/TituloConBack';
import Transaccion from './components/Transaccion';
import { getUIData, sendRequest } from '../../lib/serverAPI';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const EstadoDeCuenta = ({ usuario, ...props }) => {
  const [transacciones, setTransacciones] = useState([]);

  const [balance, setBalance] = useState(0);

  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [filtros, setFiltros] = useState(null);

  const [pageSize, setPageSize] = useState(1000);
  const [page, setPage] = useState(1);

  const [consultarDatos, setConsultarDatos] = useState(false);

  const history = useHistory();

  const actualizarFiltros = () => {
    setFiltros({ fechaDesde: fechaDesde, fechaHasta: fechaHasta });
  }


  const actualizarDatos = () => {
    setConsultarDatos(true);
  }

  const resetFechas = () => {
    let hoy = moment(new Date()).format('YYYY-MM-DD');
    let comienzoDeMes = moment(new Date()).startOf('month').format('YYYY-MM-DD');

    // setFechaDesde(comienzoDeMes);
    // setFechaDesde(0); // Mostramos todos
    // setFechaHasta(hoy);

    setTimeout(actualizarDatos, 1000);

  }

  const nextPage = () => {
    setPage(page + 1);
    actualizarDatos();
  };

  const previowsPage = () => {
    setPage(page - 1);
    actualizarDatos();
  };

  useEffect(() => {
    if (!!consultarDatos && !!filtros) {
      setConsultarDatos(false);
      getUIData('getEstadoDeCuenta', { ...filtros, orderBy: 'fecha desc, orden desc', limit: pageSize, offset: pageSize * (page - 1) }, data => {
        setBalance(data.balance ?? 0);
        if ((data.lista.length === 0) && page > 1) {
          setPage(1);
          actualizarDatos();
        } else {
          setTransacciones(data.lista.map(item => {
            // item.id = item.fecha + item.tipo + item.descripcion;
            item.tipo = item.tipo;
            item.fecha = item.fecha;
            item.importe = item.importe;
            item.descripcion = item.descripcion;
            item.saldo = item.saldo;

            return item;
          }
            ));
        }
      });
    }
  }, [consultarDatos]);

  useEffect(actualizarFiltros, [fechaDesde, fechaHasta]);

  useEffect(() => {
    if (!usuario.contratoAdminAccess) {
      // El usuario no tiene acceso al contrato, no tiene permitido ver el estado de cuenta
      history.push('/home');

    }
    resetFechas();
  }, []);

  return (
    <div className="transacciones-page">
      <TituloConBack titulo="Estado de cuenta" />
      <div className="filtros">
        <div className="input-label-combo">
          <input className={"inputFecha" + ' tiene-valor'} type="date" onInput={(event) => {setFechaDesde(event.target.value)}} value={fechaDesde} />
          <label className="is--placeholder" >Desde</label>
        </div>
        <div className="input-label-combo">
          <input className={"inputFecha" + ' tiene-valor'} type="date" onInput={(event) => {setFechaHasta(event.target.value)}} value={fechaHasta} />
          <label className="is--placeholder" >Hasta</label>
        </div>
        <button className="control--aplicar" onClick={actualizarDatos}>Aplicar filtros</button>
        <div className="input-label-combo balance">
          <label className='is--placeholder'>Balance</label>
          {/* <input className='tiene-valor hidden' value={parseFloat(balance).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}></input> */}
          <span className={balance > 0 ? 'balance__deudor' : 'balance__acreedor'}>{parseFloat(balance).toLocaleString('es-AR', { style: 'currency', currency: 'ARS' })}</span>
        </div>
      </div>
      <section className="transacciones-list">
        <div className="transacciones-columns">
          <div className="transacciones-item__date">
            <span className="transacciones-item__fecha">Fecha</span>
          </div>
          <div className="transacciones-item__details">
            <div className="transacciones-item__solicitante">
              <span>Tipo</span>
            </div>
            <div className="transacciones-item__type">
              <span>Descripcion</span>
            </div>
            <div className="transacciones-item__credits">
              <span>Importe</span>
            </div>
            <div className="transacciones-item__saldo">
              <span>Saldo</span>
            </div>
          </div>
        </div>
        <ol className="transacciones-items">
          {(page > 1) && <li>
            <span className="control--ver-mas">{'mostrando ' + transacciones.length + ' registros'}
              <button className='button--icon-only button--no-background' onClick={previowsPage}>
                <img className="control--ver-menos__icon" alt="ver más" src="img/chevron--orange.svg"></img>
              </button>
            </span>
          </li>}
          {transacciones && (transacciones.length > 0) && transacciones.map((item, index) => (
            <li key={index} className="transacciones-item">
              <Transaccion {...item} />
            </li>
          ))}
          {!!(transacciones.length === pageSize) && <li>
            <span className="control--ver-mas">{'mostrando ' + transacciones.length + ' registros'}
              <button className='button--icon-only button--no-background' onClick={nextPage}>
                <img className="control--ver-mas__icon" alt="ver más" src="img/chevron--orange.svg"></img>
              </button>
            </span>
          </li>}
        </ol>
      </section>
    </div>
  );
}

export default EstadoDeCuenta;